import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { CategoryAddComponent } from './modules/category/category-add/category-add.component';
import { CategoryComponent } from './modules/category/category.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { JobAddComponent } from './modules/job/job-add/job-add.component';
import { JobComponent } from './modules/job/job.component';
import { LoginComponent } from './modules/login/login.component';
import { NewsCategoryAddComponent } from './modules/news-category/news-category-add/news-category-add.component';
import { NewsCategoryComponent } from './modules/news-category/news-category.component';
import { NewAddComponent } from './modules/news/new-add/new-add.component';
import { NewsComponent } from './modules/news/news.component';
import { OrderEditComponent } from './modules/order/order-edit/order-edit.component';
import { OrderComponent } from './modules/order/order.component';
import { AttributeGroupAddComponent } from './modules/product/attribute-group/attribute-group-add/attribute-group-add.component';
import { AttributeGroupComponent } from './modules/product/attribute-group/attribute-group.component';
import { AttributeAddComponent } from './modules/product/attribute/attribute-add/attribute-add.component';
import { AttributeComponent } from './modules/product/attribute/attribute.component';
import { ProdcutAddComponent } from './modules/product/prodcut-add/prodcut-add.component';
import { ProductEditComponent } from './modules/product/product-edit/product-edit.component';
import { ProductComponent } from './modules/product/product.component';
import { VarientEditComponent } from './modules/product/varient-list/varient-edit/varient-edit.component';
import { VarientListComponent } from './modules/product/varient-list/varient-list.component';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';
import { AuthGuard } from './shared/auth.guard';
import { CityComponent } from './modules/city/city.component';
import { CityAddComponent } from './modules/city/city-add/city-add.component';
import { StateComponent } from './modules/state/state.component';
import { StateAddComponent } from './modules/state/state-add/state-add.component';
import { SettingComponent } from './modules/setting/setting.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'dashboard',
  //   canActivate: [AuthGuard], canActivateChild: [AuthGuard]
  // },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'news', component: NewsComponent },
      { path: 'news-add', component: NewAddComponent },
      { path: 'news-edit/:id', component: NewAddComponent },
      { path: 'news-category', component: NewsCategoryComponent },
      { path: 'news-category-add', component: NewsCategoryAddComponent },
      { path: 'news-category-edit/:id', component: NewsCategoryAddComponent },
      { path: 'category', component: CategoryComponent },
      { path: 'category-add', component: CategoryAddComponent },
      { path: 'category-edit/:id', component: CategoryAddComponent },
      { path: 'job', component: JobComponent },
      { path: 'job-add', component: JobAddComponent },
      { path: 'job-edit/:id', component: JobAddComponent },
      { path: 'product', component: ProductComponent },
      { path: 'product-add', component: ProdcutAddComponent },
      { path: 'product-edit/:id', component: ProductEditComponent },
      { path: 'product/:id/variants', component: VarientListComponent },
      { path: 'product/:pid/variant-add', component: VarientEditComponent },
      { path: 'product/:pid/variant-edit/:vid', component: VarientEditComponent },
      { path: 'product/attribute-group', component: AttributeGroupComponent },
      { path: 'product/attribute-group/attributes/:id', component: AttributeComponent },
      { path: 'user-profile', component: UserProfileComponent },
      { path: 'order', component: OrderComponent },
      { path: 'order-edit/:id', component: OrderEditComponent },
      { path: 'city', component: CityComponent },
      { path: 'city-add', component: CityAddComponent },
      { path: 'city-edit/:id', component: CityAddComponent },
      { path: 'state', component: StateComponent },
      { path: 'state-add', component: StateAddComponent },
      { path: 'state-edit/:id', component: StateAddComponent },
      { path: 'setting', component: SettingComponent }
    ],
    canActivate: [AuthGuard], canActivateChild: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
