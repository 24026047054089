
<mat-card>
  <mat-card-title>Order</mat-card-title>
  <mat-card-content>

    <div style="margin: 3em 0 3em 0;">
      <p>Name: {{ orderData?.fullName || null}}</p>
      <p>Address: {{ orderData?.addressLine1}}, {{orderData?.addressLine2}}, {{orderData?.zipcode}}, {{orderData?.city}},
        {{orderData?.state}}, {{orderData?.country}}</p>
      <p>Final Amount: {{orderData?.orderFinalAmount}}</p>
      <p>Order Status: {{orderData?.orderStatus}}</p>
      <p>Payment Status: {{orderData?.paymentStatus}}</p>

    </div>
    <form [formGroup]="orderForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch"
      fxLayout="column">
      <mat-grid-list cols="2" rowHeight="5em">
        <mat-grid-tile>
          <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
            <mat-label>Order Status</mat-label>
            <mat-select required [ngModel] name="orderStatus" formControlName="orderStatus">
              <mat-option *ngFor="let orderItem of orderStatuses" [value]="orderItem">{{
                orderItem
                }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="(orderFormControl.orderStatus.touched || orderFormControl.orderStatus.dirty ) && orderFormControl.orderStatus.errors?.required">
              OrderStatus is required</mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Payment Status</mat-label>
            <mat-select required [ngModel] name="paymentStatus" formControlName="paymentStatus">
              <mat-option *ngFor="let payment of paymentStatuses" [value]="payment">{{
                payment
                }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="(orderFormControl.paymentStatus.touched || orderFormControl.paymentStatus.dirty ) && orderFormControl.paymentStatus.errors?.required">
              PaymentStatus is required</mat-error>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="2" rowHeight="5em">
        <button mat-raised-button color="primary" class="buttom-button"> Save </button>
        <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
      </mat-grid-list>


    </form>

  </mat-card-content>
  <div id="loader" *ngIf="flag">
    <mat-progress-spinner id="loader-com" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</mat-card>
<simple-notifications></simple-notifications>




<mat-card>
  <mat-card-title>
    Order Items
  </mat-card-title>
  <mat-card-content>
    <mat-grid-list cols="2" rowHeight="6em" *ngFor="let item of orderItems" class="script-menu">
      <mat-grid-tile class="imageCol">
        <img mat-card-image class="itemImg" [src]="item.variantThumb" alt="item.variantName">
      </mat-grid-tile>

      <mat-grid-tile class="itemDetailCol">
        <h3>{{item.variantName}}</h3>
        <!-- <h3 *ngFor="let at of item.variantAttributes">{{at.attributeGroup.attributeGroupName}}: {{at.attribute.attributeName}}</h3> -->
        <div style="display: flex;flex-direction: row;">
          <div>Qty: {{item.quantity}}</div>
          <div style="position: absolute;right: 5em;">price: {{item.variantPrice}}</div>
        </div>

      </mat-grid-tile>
    </mat-grid-list>

  </mat-card-content>
</mat-card>
<simple-notifications></simple-notifications>
