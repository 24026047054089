import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
// import CircularProgress from '@material-ui/core/CircularProgress';

const sortDescriptionLength = 100;
@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit {
  public orderForm: FormGroup;
  orderStatuses = [];
  paymentStatuses = [];
  orderItems = [];
  orderData = null;
  flag = false;
  access_token = localStorage.getItem('access_token')
  order_id: string = null;
  file = null;

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService) {
    this.orderForm = new FormGroup({
      orderStatus: new FormControl([], [Validators.required]),
      paymentStatus: new FormControl([], [Validators.required]),
    });

    this.order_id = this.actRoute.snapshot.params.id;
    if (this.order_id) {
      this.http.get<any>(environment.apiBaseURL + '/order/' + this.order_id, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: data => {
          data = data.data[0];
          if(data) {
            this.orderData = data;
            this.orderItems = data.orderItem.map(item => {
              return {
                ...item,
                variantAttributes: JSON.parse(item.variantAttributes)
              }
            });
            this.orderForm = new FormGroup({
              orderStatus: new FormControl(data.orderStatus, [Validators.required]),
              // productSKU: new FormControl('', [Validators.required]),
              paymentStatus: new FormControl(data.paymentStatus || null, [Validators.required]),
            });
          }
          else{
            this.router.navigate(['order']);
          }

        }
        , error: err => {
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }
    else {
      this.router.navigate(['product']);
    }


    let orderStatus = this.http.get<any>(environment.apiBaseURL + '/order/order-status', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });
    let paymentStatus = this.http.get<any>(environment.apiBaseURL + '/order/payment-status', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });

    // this.flag = true;
    forkJoin([orderStatus, paymentStatus]).subscribe({
      next: response => {
        this.orderStatuses = Object.keys(response[0]);
        this.paymentStatuses = Object.keys(response[1]);
        // this.flag = false;
      },
      error: err => {
        this.orderStatuses = [];
        this.paymentStatuses = [];
        // this.flag = false;
      }
    })
  }

  onUploadClicked(index = null) {
    let formData = new FormData();
    formData.append('file', this.file);
    this.http.post(environment.imageBaseURL, formData, {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + environment.imageToken
      })
    }).subscribe({
      next: (response) => {
        if (response['statusCode'] && response['statusCode'] == 200) {
          this.orderForm.patchValue({ 'productThumb': 'http://api.khakiwala.com:8080/' + response['data'] });
        }
      },
      error: (error) => {
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  onSelectedFilesChanged(data) {
    if (data && data[0]) {
      this.file = data[0];
    }
  }

  get orderFormControl() {
    return this.orderForm.controls;
  }

  ngOnInit(): void { }

  onSubmit() {

    if (this.orderForm.valid) {
      //   // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      //   console.table(this.orderForm.value);
      let data = this.orderForm.value;

      let dataToSend = {
        "orderStatus": data.orderStatus || "",
        "paymentStatus": data.paymentStatus || "",
      }

      console.log("data : ", dataToSend);

      if (this.order_id) {
        this.http.put(environment.apiBaseURL + '/order/' + this.order_id, dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: async (data) => {

            this.notifications.create("Success",
              "Order successfully updated",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            // this.getItems();
            this.router.navigate(['order']);
          },
          error: error => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }

  onCancel() {
    this.router.navigate(['order']);
  }

}




