import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';



const ELEMENT_DATA = [];
const countManage = {
  limit: 10,
  offsef: 0,
}

@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.css']
})
export class AttributeComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'attributeName',
    // 'action',
  ];
  attributeName = null;
  dataSource = ELEMENT_DATA;
  access_token = localStorage.getItem('access_token')
  attribute_group_id: string = null;
  attribute_id = null;
  showSpinner = false;
  atrribute_group_name = "";

  constructor(private router: Router, private http: HttpClient, public dialog: MatDialog, private actRoute: ActivatedRoute, private notifications: NotificationsService) {
    this.attribute_group_id = this.actRoute.snapshot.params.id;
    this.atrribute_group_name = this.actRoute.snapshot.params.attribute;
    // console.log("@@ : ", this.atrribute_group_name)
    this.getItems();
  }

  ngOnInit(): void {
    // this.getItems();
  }

  addAttribute() {
    if(this.attributeName && this.attributeName != "" && this.attribute_group_id){
      let dataToSend = {
        "attributeName": this.attributeName || "",
        "attributeGroup": this.attribute_group_id
      }

      if(this.attribute_id){
        this.http.put(environment.apiBaseURL+'/product/attribute/'+this.attribute_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            // this.router.navigate(['product','attribute']);
            this.getItems();
            this.notifications.create("Success",
              "Attribute updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else{
        this.http.post(environment.apiBaseURL+'/product/attribute',dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            // this.router.navigate(['product','attribute']);
            this.getItems()
            this.notifications.create("Success",
              "Attribute Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
    
  }

  clearData(){
    this.attributeName = null;
  }

  cancelManage(){
    this.attribute_id = null;
    this.attributeName = null;
  }

  getItems() {
    if (this.attribute_group_id) {
      this.showSpinner = true;
      const url = `${environment.apiBaseURL}/product/attribute-group/attributes/${this.attribute_group_id}`;
      this.http.get<any>(url, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: data => {
          if (data) {
            this.showSpinner = false;
            this.dataSource = data;
          }
          //this.total = data.total || 0;
        },
        error: error => {
          this.dataSource = [];
          this.showSpinner = false;
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }
  }

  // chnageRecords(event){
  //   this.numberOfRecords = parseInt(event.value);
  //   countManage.limit = parseInt(event.value);
  //   this.getItems();
  // }

  // getNewRecords(event){
  //   if(event == 'prev'){
  //     if((this.total >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)){
  //       countManage.offsef = countManage.offsef - countManage.limit;
  //       this.getItems();
  //     }
  //   }
  //   else{
  //     if(this.total >= countManage.offsef + countManage.limit){
  //       countManage.offsef = countManage.offsef + countManage.limit;
  //       this.getItems();
  //     }
  //   }

  // }


  manageAttribute(id) {
    this.dataSource.forEach(item => {
      if(item.id == id){
        this.attribute_id = item.id;
        this.attributeName = item.attributeName;
      }
    })
  }

  removeAttribute(){

  }
}



