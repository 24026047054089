import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-attribute-add',
  templateUrl: './attribute-add.component.html',
  styleUrls: ['./attribute-add.component.css']
})
export class AttributeAddComponent implements OnInit {
  public addattributeNameForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  attr_id:string = null;
  minDate = new Date();



  States: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.addattributeNameForm = new FormGroup({
      attributeName: new FormControl('', [Validators.required]),
    });


    this.attr_id = this.actRoute.snapshot.params.id;
    if(this.attr_id){
      this.http.get<any>(environment.apiBaseURL+'/product/attribute/'+this.attr_id,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data[0];
          this.addattributeNameForm = new FormGroup({
            attributeName: new FormControl(data.attributeName || "", [Validators.required]),
          });

        },
        error: error => {
          this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }
  }

  get attributeFormControl() {
    return this.addattributeNameForm.controls;
  }

  ngOnInit(): void {}

  onSubmit(){
    if (this.addattributeNameForm.valid) {
      // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.addattributeNameForm.value);

      let data = this.addattributeNameForm.value;
      let dataToSend = {
        "attributeName": data.attributeName || "",
      }

    
      if(this.attr_id){
        this.http.put(environment.apiBaseURL+'/product/attribute/'+this.attr_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.router.navigate(['product','attribute']);
            this.notifications.create("Success",
              "Attribute updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else{
        this.http.post(environment.apiBaseURL+'/product/attribute',dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            this.router.navigate(['product','attribute']);
            this.notifications.create("Success",
              "Attribute Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }
}

