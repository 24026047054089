import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-state-add',
  templateUrl: './state-add.component.html',
  styleUrls: ['./state-add.component.css']
})
export class StateAddComponent implements OnInit {
  public addStateForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  state_id: string = null;

  Countries: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.addStateForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      Country: new FormControl('', [Validators.required]),
      is_active: new FormControl(true),
      name_hi: new FormControl(''),
      name_gu: new FormControl('')
    });


    this.state_id = this.actRoute.snapshot.params.id;
    if (this.state_id) {
      this.http.get<any>(environment.apiBaseURL + '/state/' + this.state_id, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data.data

          this.addStateForm = new FormGroup({
            name: new FormControl(data.name || "", [Validators.required]),
            Country: new FormControl(Number(data.country_id) || null, [Validators.required]),
            is_active: new FormControl(data.is_active),
            name_hi: new FormControl(data.name_hi || ""),
            name_gu: new FormControl(data.name_gu || "")
          });

        },
        error: error => {
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }

    this.http.get<any>(environment.apiBaseURL + '/geocoding/country', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        response = response.data
        this.Countries = response;
        console.log('Country: ', this.Countries);
      },
      error: error => {
        this.Countries = [];
      }
    })


  }

  ngOnInit(): void {
  }
  get stateFormControl() {
    return this.addStateForm.controls;
  }

  onSubmit() {
    if (this.addStateForm.valid) {
      // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.addStateForm.value);
      let data = this.addStateForm.value;

      let dataToSend = {
        "name": data.name || "",
        "country_id": data.Country,
        "is_active": data.is_active || false,
        "name_hi": data.name_hi || "",
        "name_gu": data.name_gu || "",
      }
      console.log(dataToSend);

      if (this.state_id) {
        this.http.put(environment.apiBaseURL + '/state/' + this.state_id, dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.router.navigate(['state']);
            this.notifications.create("Success",
              "State Updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else {
        this.http.post(environment.apiBaseURL + '/state', dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            this.router.navigate(['state']);
            this.notifications.create("Success",
              "State Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }

  onCancel() {
    this.router.navigate(['state']);
  }

}
