import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(private authService: AuthService,private router: Router) {}

  ngOnInit(): void {}

  toggleSidebar() {
    this.toggleSideBarForMe.emit();
  }

  signOut(){
    this.authService.signOut();
    this.router.navigate(['login']);
  }
}
