import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AuthService } from '../../shared/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  helper = new JwtHelperService();

  constructor(private authService: AuthService,private notifications: NotificationsService, private router: Router) {
    if(JSON.parse(localStorage.getItem('user'))){
      this.router.navigate(['/']);
    }
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }
  onSubmit() {
    if (this.loginForm.valid) {
        this.authService.signIn(this.loginForm.value).subscribe({
          next: data => {
            data = data.data;
            
            let decodedToken = this.helper.decodeToken(data.access_token);
            let isExpired = this.helper.isTokenExpired(data.access_token);

            console.log("@@@ : ", decodedToken, isExpired);
            if(decodedToken.user.profile != "admin" && !isExpired){
              this.notifications.create("Error","Unauthorized user",
                NotificationType.Error, { timeOut: 3000, showProgressBar: true });
                this.router.navigate(['login']);
            }
            this.authService.setUserData({
              userData: decodedToken,
              access_token: data.access_token
            });
            this.router.navigate(['/news']);
          },
          error: error => {
            this.notifications.create("Error",error.statusText,
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        });
    }
    // this.router.navigate(['/dashboard']);
  }
}
