<mat-card>
    <mat-card-title>News</mat-card-title>
    <mat-card-content>
        <form [formGroup]="addNewsForm" class="example-form" fxLayoutAlign="stretch" fxLayout="column">
            <mat-form-field class="example-full-width">
                <mat-label>Title</mat-label>
                <input matInput placeholder="Title" required ngModel name="title" formControlName="title" />
                <mat-error
                    *ngIf="(newsFormControl.title.touched || newsFormControl.newsType.dirty) && newsFormControl.title.errors?.required">
                    Title is required</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Short Description</mat-label>
                <input matInput placeholder="Short Description" required ngModel name="shortDescription"
                    formControlName="shortDescription" />
                <mat-error
                    *ngIf="(newsFormControl.shortDescription.touched || newsFormControl.shortDescription.dirty) && newsFormControl.shortDescription.errors?.required">
                    Short Description is required</mat-error>
                <mat-error *ngIf="newsFormControl.shortDescription.errors?.maxlength">Maximum length is 100</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
                <mat-label>News Type</mat-label>
                <mat-select required [ngModel]="selectedType" ngModel name="newsType" formControlName="newsType"
                    (selectionChange)="changeNewsType($event)">
                    <mat-option *ngFor="let type of newsType" [value]="type.value">
                        {{ type.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="(newsFormControl.newsType.touched || newsFormControl.newsType.dirty) && newsFormControl.newsType.errors?.required">
                    Type is required</mat-error>
            </mat-form-field>

            <mat-label *ngIf="addNewsForm.value.newsType != 'Other News'">Description</mat-label>
            <!-- <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <quill-editor [styles]="{ height: '235px' }"></quill-editor>
      </mat-form-field> -->
            <ckeditor *ngIf="addNewsForm.value.newsType != 'Other News'" [styles]="{ height: '235px' }" required
                [ngModel] name="description" formControlName="description"></ckeditor>
            <!-- <quill-editor *ngIf="addNewsForm.value.newsType != 'Other News'" [styles]="{ height: '235px' }" required [ngModel] name="description" formControlName="description"></quill-editor> -->
            <mat-error
                *ngIf="(newsFormControl.description.touched || newsFormControl.description.dirty ) && newsFormControl.description.errors?.required && addNewsForm.value.newsType != 'Other News'">
                Description is required</mat-error>


            <mat-form-field class="example-full-width" style="width: 100%;">
                <mat-label>Tags</mat-label>
                <input matInput placeholder="eg. aa,bb,cc,.." required ngModel name="tags" formControlName="tags" />
                <mat-error
                    *ngIf="(newsFormControl.tags.touched || newsFormControl.tags.dirty ) && newsFormControl.tags.errors?.required">
                    Tag is required</mat-error>
            </mat-form-field>

            <!-- <mat-grid-list cols="3" rowHeight="3em" style="margin-bottom: 20px;">
                <mat-grid-tile class="file-upload" style="display: block;">
                    <mat-file-upload class="example-full-width1" style="width: 100%;" [selectButtonText]="'Choose File'"
                        [acceptedTypes]="'.png, .jpg, .jpeg'" [uploadButtonText]="'Upload File'"
                        [allowMultipleFiles]="false" [showUploadButton]="true" [customSvgIcon]="''"
                        [acceptedTypes]="'.png, .jpg, .jpeg'" (uploadClicked)="onUploadClicked($event)"
                        (selectedFilesChanged)="onSelectedFilesChanged($event)">
                    </mat-file-upload> 
                </mat-grid-tile>
                <mat-grid-tile class="file-upload">
                    <button mat-raised-button color="primary" class="buttom-button"
                        (click)="onUploadClicked($event)">Upload File</button>
                </mat-grid-tile>
                <mat-grid-tile>
                    <label>OR</label>
                </mat-grid-tile>
            </mat-grid-list> -->


            <!-- <mat-grid-list cols="5" rowHeight="5em" *ngIf="videoSelected">
                <mat-grid-tile colspan="4">
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Video Url</mat-label>
                        <input matInput placeholder="video_link" ngModel name="video_link" formControlName="video_link"
                            (keyup)="onChangeEvent($event.target)" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-label>OR</mat-label>
                </mat-grid-tile>
            </mat-grid-list> -->

            <mat-grid-list cols="5" rowHeight="5em" *ngIf="imageUrlSelected">
                <mat-grid-tile colspan="4">
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Image Url</mat-label>
                        <input matInput placeholder="thumbnail" ngModel name="thumbnail" formControlName="thumbnail"
                            (keyup)="onChangeEvent($event.target)" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-label>OR</mat-label>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-card class="example-card" *ngIf="imageSelected">
                <mat-card-title-group>
                    <mat-card-subtitle style="color: black;margin-top: 70px;">
                        <input type="file" #fileInput id="uploadFile" (change)="onSelectedFilesChanged($event)"
                            accept="image/*" />
                        <span color="primary" class="buttom-upload" (click)="onUploadClicked($event)">Upload File</span>
                    </mat-card-subtitle>

                    <img mat-card-md-image src="{{imageUrl}}" *ngIf="uploadImage">
                    <span aria-hidden="true" class="material-icons close" (click)="onRemoveImage()"
                        *ngIf="uploadImage">clear</span>
                </mat-card-title-group>
            </mat-card>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Source</mat-label>
                        <input matInput placeholder="Source" required ngModel name="source" formControlName="source" />
                        <mat-error
                            *ngIf="(newsFormControl.source.touched || newsFormControl.source.dirty ) && newsFormControl.source.errors?.required">
                            Source is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%;">
                        <mat-label>Source Link</mat-label>
                        <input matInput placeholder="Source Link" required ngModel (change)="onSourceLinkChange($event)" name="sourceLink"
                            formControlName="sourceLink" />
                        <mat-error
                            *ngIf="(newsFormControl.sourceLink.touched || newsFormControl.sourceLink.dirty ) && newsFormControl.sourceLink.errors?.required">
                            SourceLink is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            
            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
                        <mat-label>Language</mat-label>
                        <mat-select required ngModel name="language" formControlName="language">
                            <mat-option *ngFor="let type of languageType" [value]="type.value">
                                {{ type.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="(newsFormControl.language.touched || newsFormControl.language.dirty ) || (newsFormControl.language.errors?.required)">
                            Language is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>Category</mat-label>
                        <mat-select multiple required ngModel name="category" formControlName="category">
                            <mat-option *ngFor="let category of Categories" [value]="category.id">{{
                                category.categoryName }}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-error *ngIf="(newsFormControl.category.touched || newsFormControl.category.dirty ) && newsFormControl.category.errors?.required">Category is required</mat-error> -->
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>



            <!-- <mat-form-field appearance="fill">
        <mat-label>Publish Date</mat-label>
        <input matInput [matDatepicker]="picker" required ngModel name="publishDate" formControlName="publishDate"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="(newsFormControl.publishDate.touched || newsFormControl.publishDate.dirty ) && newsFormControl.publishDate.errors?.required">Publish Date is required</mat-error>
      </mat-form-field> -->


            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
                        <mat-label>State</mat-label>
                        <mat-select required ngModel name="state" formControlName="state"
                            (selectionChange)="getCities($event)">
                            <mat-option *ngFor="let state of States" [value]="state.id">{{ state.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="(newsFormControl.state.touched || newsFormControl.state.dirty ) && newsFormControl.state.errors?.required">
                            State is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>City</mat-label>
                        <mat-select required ngModel name="city" formControlName="city">
                            <mat-option *ngFor="let city of Cities" [value]="city.id">{{ city.city }}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-error *ngIf="(newsFormControl.city.touched || newsFormControl.city.dirty ) && newsFormControl.city.errors?.required">City is required</mat-error> -->
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>



            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
                        <mat-label>Priority</mat-label>
                        <mat-select required ngModel name="priority" formControlName="priority">
                            <mat-option *ngFor="let type of priorityType" [value]="type.value">
                                {{ type.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="(newsFormControl.priority.touched || newsFormControl.priority.dirty ) && newsFormControl.priority.errors?.required">
                            Priority is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <section class="example-section" style="width: 100%;">
                        <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" name="isPublished"
                            formControlName="isPublished">Is Published</mat-checkbox>
                        <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" name="isFeatured"
                            formControlName="isFeatured">Is Featured</mat-checkbox>
                        <mat-checkbox style="margin: 10px;" color="primary" [checked]="newsFormControl.isVideoSource.value" class="example-margin" name="isVideoSource"
                            formControlName="isVideoSource">Is Video Source</mat-checkbox>
                    </section>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <button mat-raised-button color="primary" class="buttom-button" (click)="onSubmit()"> Save </button>
                <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
            </mat-grid-list>
        </form>

    </mat-card-content>
    <div id="loader" *ngIf="flag">
        <mat-progress-spinner id="loader-com" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</mat-card>
<simple-notifications></simple-notifications>