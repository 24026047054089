<mat-card>
    <mat-card-title>Attribute</mat-card-title>
    <mat-card-content>
      <form [formGroup]="addattributeNameForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">
  
        <mat-form-field class="example-full-width">
          <mat-label>Attribute Name</mat-label>
          <input matInput placeholder="attributeName" required ngModel name="attributeName" formControlName="attributeName" />
          <mat-error *ngIf="(attributeFormControl.attributeName.touched || attributeFormControl.attributeName.dirty) && attributeFormControl.attributeName.errors?.required">Attribute name is required</mat-error>
        </mat-form-field>
  

        <button mat-raised-button color="primary" class="buttom-button">
          Save
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <simple-notifications></simple-notifications>
  