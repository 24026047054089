import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface State {
  id: number;
  name: string;
  is_active: number;
  countryName: string;
  country_id: string
  createdDate: string;
}

const ELEMENT_DATA: State[] = [];
const countManage = {
  limit: 10,
  offsef: 0,
}
interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {
  total = 0;
  count = 0
  searchTerm = '';
  numberOfRecords = 10;
  searchSub: Subscription;
  showSpinner = false;
  displayedColumns: string[] = [
    'id',
    'name',
    'countryName',
    // 'createdDate',
    'is_active',
    'update'
  ];
  dataSource = ELEMENT_DATA;
  access_token = localStorage.getItem('access_token')

  languageType: Language[] = [
    { value: 'en', viewValue: 'English' },
    { value: 'gu', viewValue: 'Gujarati' },
    { value: 'hi', viewValue: 'Hindi' },
  ]

  constructor(private router: Router, private http: HttpClient, private notifications: NotificationsService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getItems();
  }

  AddStateClick() {
    this.router.navigate(['/state-add']);
  }

  clearSearchData() {
    this.searchTerm = '';
    this.getItems();

  }

  searchItems(event){
    countManage.offsef = 0;
    this.getItems();
  }

  getItems() {
    this.showSpinner = true;
    const url = `${environment.apiBaseURL}/state?limit=${countManage.limit}&offset=${countManage.offsef}&searchTerm=${this.searchTerm}`;
    this.http.get<any>(url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        this.count = data.total;
        if (data.data) {
          this.showSpinner = false;
          this.dataSource = data.data;
          this.http.get<any>(environment.apiBaseURL + '/geocoding/country', {
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this.access_token
            })
          }).subscribe({
            next: (response) => {
              this.dataSource.forEach(data => {
                let countryData = response.data.filter(res => res.id == Number(data.country_id));
                data.countryName = countryData[0].name
              })
            }
          });

          this.total = data.data.length;
        } else {
          this.showSpinner = false;
          this.total = data.total || 0;
        }
      },
      error: error => {
        this.showSpinner = false;
        this.dataSource = [];
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  chnageRecords(event) {
    this.numberOfRecords = parseInt(event.value);
    countManage.limit = parseInt(event.value);
    this.getItems();
  }

  getNewRecords(event) {
    if (event == 'prev') {
      if ((this.count >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)) {
        countManage.offsef = countManage.offsef - countManage.limit;
        this.getItems();
      }
    }
    else {
      if (this.count >= countManage.offsef + countManage.limit) {
        countManage.offsef = countManage.offsef + countManage.limit;
        this.getItems();
      }
    }
  }

  editCheckbox(event) {
    let dataToSend = {
      "name": event.name,
      "is_active": event.is_active
    }
    if (event.id) {
      this.http.put(environment.apiBaseURL + '/state/' + event.id, dataToSend, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          this.router.navigate(['state']);
          this.notifications.create("Success",
            "Status Updated Successfully",
            NotificationType.Success, { timeOut: 3000, showProgressBar: true });
        },
        error: error => {
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }
  }

}
