<mat-card>
    <mat-card-title>Product
        <button mat-raised-button style="width: 80px;height: 40px;padding: unset;position: absolute;right: 1em;"
            color="primary" (click)="AddVarientClick()">
            Add Varient
        </button>

    </mat-card-title>

    <mat-card-content>
        <form [formGroup]="addProductForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch"
            fxLayout="column">

            <mat-form-field class="example-full-width">
                <mat-label>Product Name</mat-label>
                <input matInput placeholder="Product Name" required ngModel name="productName"
                    formControlName="productName" />
                <mat-error
                    *ngIf="(newsFormControl.productName.touched || newsFormControl.productName.dirty) && newsFormControl.productName.errors?.required">
                    Product name is required</mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width">
        <mat-label>Product SKU</mat-label>
        <input matInput placeholder="Product SKU" required ngModel name="productSKU" formControlName="productSKU" />
        <mat-error
          *ngIf="(newsFormControl.productSKU.touched || newsFormControl.productSKU.dirty) && newsFormControl.productSKU.errors?.required">
          Product SKU is required</mat-error>
      </mat-form-field> -->

            <mat-form-field class="example-full-width">
                <mat-label>Product MRP</mat-label>
                <input matInput placeholder="Product MRP" required ngModel name="productMRP"
                    formControlName="productMRP" />
                <mat-error
                    *ngIf="(newsFormControl.productMRP.touched || newsFormControl.productMRP.dirty) && newsFormControl.productMRP.errors?.required">
                    Product MRP is required</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Product Price</mat-label>
                <input matInput placeholder="Product Price" required ngModel name="productPrice"
                    formControlName="productPrice" />
                <mat-error
                    *ngIf="(newsFormControl.productPrice.touched || newsFormControl.productPrice.dirty) && newsFormControl.productPrice.errors?.required">
                    Product price is required</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Product Discount</mat-label>
                <input matInput placeholder="Product Discount" readonly required
                    [ngModel]="((100*(addProductForm.value.productMRP - addProductForm.value.productPrice))/addProductForm.value.productMRP) || 0"
                    name="productDiscount" formControlName="productDiscount" />
                <mat-error
                    *ngIf="(newsFormControl.productDiscount.touched || newsFormControl.productDiscount.dirty) && newsFormControl.productDiscount.errors?.required">
                    Product Discount is required</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Product Short Description</mat-label>
                <input matInput placeholder="Product Short Description" required ngModel name="productShortDescription"
                    formControlName="productShortDescription" />
                <mat-error
                    *ngIf="(newsFormControl.productShortDescription.touched || newsFormControl.productShortDescription.dirty) && newsFormControl.productShortDescription.errors?.required">
                    Prouct Short Description is required</mat-error>
            </mat-form-field>

            <mat-label *ngIf="addProductForm.value.newsType != 'Other News'">Product Description</mat-label>
            <ckeditor required ngModel name="productDescription" formControlName="productDescription"></ckeditor>
            <!-- <quill-editor [styles]="{ height: '235px' }" required ngModel name="productDescription"
        formControlName="productDescription"></quill-editor> -->
            <mat-error
                *ngIf="(newsFormControl.productDescription.touched || newsFormControl.productDescription.dirty ) && newsFormControl.productDescription.errors?.required">
                Product description is required</mat-error>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%;margin-right: 1em;">
                        <mat-label>Product thumb</mat-label>
                        <input matInput placeholder="Product Thumbnail" required ngModel name="productThumb"
                            formControlName="productThumb" />
                        <mat-error
                            *ngIf="(newsFormControl.productThumb.touched || newsFormControl.productThumb.dirty ) && newsFormControl.productThumb.errors?.required">
                            Product thumbnail is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <label>OR</label>
                    <!-- <mat-file-upload style="width: 100%;" [labelText]="''" [selectButtonText]="'Choose File'"
            [acceptedTypes]="'.png, .jpg, .jpeg'" [uploadButtonText]="'Upload File'" [allowMultipleFiles]="false"
            [showUploadButton]="true" [customSvgIcon]="''" [acceptedTypes]="'.png, .jpg, .jpeg'"
            (uploadClicked)="onUploadClicked()" (selectedFilesChanged)="onSelectedFilesChanged($event)">
          </mat-file-upload> -->
                </mat-grid-tile>
            </mat-grid-list>


            <mat-form-field class="example-full-width">
                <mat-label>Product Slug</mat-label>
                <input matInput placeholder="Product Slug" required ngModel name="productSlug"
                    formControlName="productSlug" />
                <mat-error
                    *ngIf="(newsFormControl.productSlug.touched || newsFormControl.productSlug.dirty) && newsFormControl.productSlug.errors?.required">
                    Prouct slug is required</mat-error>
            </mat-form-field>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-form-field style="width: 100%;">
                    <mat-label>Category</mat-label>
                    <mat-select required ngModel name="category" formControlName="category">
                        <mat-option *ngFor="let category of Categories" [value]="category.id">{{ category.categoryName
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-form-field style="width: 100%;">
                    <mat-label>Tax Slab</mat-label>
                    <mat-select required ngModel name="taxSlab" formControlName="taxSlab">
                        <mat-option *ngFor="let tax_slab of TaxSlab" [value]="tax_slab.id">{{ tax_slab.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <section class="example-section" style="width: 100%;">
                        <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel
                            name="stockStatus" formControlName="stockStatus">
                            Out of Stock
                        </mat-checkbox>
                        <!-- <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="isFeatured" formControlName="isFeatured">Is Featured</mat-checkbox> -->
                    </section>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <button mat-raised-button color="primary" class="buttom-button" (click)="AddVarientClick()"> Add Varient
                </button>
            </mat-grid-list>

            <mat-card-content *ngFor="let varientForm of varientForms; let i = index;"
                style="margin-top: 2em;border: 1px solid black;padding: 2em; border-radius: 1em;">

                <button type="button" mat-raised-button *ngIf="i!==0"
                    style="position: absolute;left: 3em;background-color: red;" color="primary"
                    (click)="removeVarient(i)">
                    <mat-icon>close</mat-icon>
                </button>
                <button type="button" mat-raised-button
                    style="width: fit-content;height: fit-content;position: absolute;right: 3em;" color="primary"
                    (click)="addAttributes(i)">
                    Add Varient Attribute
                </button>
                <form [formGroup]="varientForm" class="example-form" fxLayoutAlign="stretch" fxLayout="column"
                    style=" margin-top: 3em;">

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Name</mat-label>
                        <input matInput placeholder="Varient Name" required ngModel name="varientName"
                            formControlName="varientName" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientName.touched || varientForm.controls.varientName.dirty) && varientForm.controls.varientName.errors?.required">
                            varient name is required</mat-error>
                    </mat-form-field>

                    <mat-grid-list cols="2" rowHeight="5em">
                        <mat-grid-tile>
                            <mat-form-field class="example-full-width" style="width: 100%;margin-right: 1em;">
                                <mat-label>varient thumb</mat-label>
                                <input matInput placeholder="Product Thumbnail" required ngModel name="varientThumb"
                                    formControlName="varientThumb" />
                                <mat-error
                                    *ngIf="(varientForm.controls.varientThumb.touched || varientForm.controls.varientThumb.dirty ) && varientForm.controls.varientThumb.errors?.required">
                                    Varient thumbnail is required</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <label>OR</label>
                            <!-- <mat-file-upload style="width: 100%;" [labelText]="''" [selectButtonText]="'Choose File'"
                [acceptedTypes]="'.png, .jpg, .jpeg'" [uploadButtonText]="'Upload File'" [allowMultipleFiles]="false"
                [showUploadButton]="true" [customSvgIcon]="''" [acceptedTypes]="'.png, .jpg, .jpeg'"
                (uploadClicked)="onUploadClicked(i)" (selectedFilesChanged)="onSelectedFilesChanged($event)">
              </mat-file-upload> -->
                        </mat-grid-tile>
                    </mat-grid-list>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Slug</mat-label>
                        <input matInput placeholder="Product Slug" required ngModel name="varientSlug"
                            formControlName="varientSlug" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientSlug.touched || varientForm.controls.varientSlug.dirty) && varientForm.controls.varientSlug.errors?.required">
                            Varient slug is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient MRP</mat-label>
                        <input matInput placeholder="Product MRP" required ngModel name="varientMRP"
                            formControlName="varientMRP" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientMRP.touched || varientForm.controls.varientMRP.dirty) && varientForm.controls.varientMRP.errors?.required">
                            Varient MRP is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Price</mat-label>
                        <input matInput placeholder="Product Price" required ngModel name="varientPrice"
                            formControlName="varientPrice" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientPrice.touched || varientForm.controls.varientPrice.dirty) && varientForm.controls.varientPrice.errors?.required">
                            Varient price is required</mat-error>
                    </mat-form-field>

                    <!-- <mat-form-field class="example-full-width">
            <mat-label>varient SKU</mat-label>
            <input matInput placeholder="Product SKU" required ngModel name="varientSKU" formControlName="varientSKU" />
            <mat-error
              *ngIf="(varientForm.controls.varientSKU.touched || varientForm.controls.varientSKU.dirty) && varientForm.controls.varientSKU.errors?.required">
              varient SKU is required</mat-error>
          </mat-form-field> -->

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Discount</mat-label>
                        <input matInput placeholder="Product Discount" readonly required
                            [ngModel]="((100 * (varientForm.value.varientMRP - varientForm.value.varientPrice) / varientForm.value.varientMRP)) || 0"
                            name="varientDiscount" formControlName="varientDiscount" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientDiscount.touched || varientForm.controls.varientDiscount.dirty) && varientForm.controls.varientDiscount.errors?.required">
                            Varient Discount is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Weight</mat-label>
                        <input matInput placeholder="varient Weight" required ngModel name="varientWeight"
                            formControlName="varientWeight" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientWeight.touched || varientForm.controls.varientWeight.dirty) && varientForm.controls.varientWeight.errors?.required">
                            varient weight is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Qtys</mat-label>
                        <input matInput placeholder="varient Qtys" required ngModel name="varientQtys"
                            formControlName="varientQtys" />
                        <mat-error
                            *ngIf="(varientForm.controls.varientQtys.touched || varientForm.controls.varientQtys.dirty) && varientForm.controls.varientQtys.errors?.required">
                            varient qtys is required</mat-error>
                    </mat-form-field>

                    <mat-grid-list cols="2" rowHeight="5em">
                        <mat-grid-tile>
                            <section class="example-section" style="width: 100%;">
                                <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel
                                    name="stockStatus" formControlName="stockStatus">Out of Stock</mat-checkbox>
                                <!-- <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="isFeatured" formControlName="isFeatured">Is Featured</mat-checkbox> -->
                            </section>
                        </mat-grid-tile>
                    </mat-grid-list>

                </form>

                <mat-card-content *ngFor="let varientAttribute of varientAttributesForms[i]; let j = index;"
                    style="margin-top: 2em;border: 1px solid black;padding: 0 2em 0 2em; border-radius: 1em;">

                    <mat-icon style="color: red;right: 2.5em;position: absolute;margin-top: 0.5em;"
                        (click)="removeAttributes(i,j)">close</mat-icon>
                    <form [formGroup]="varientAttribute" class="example-form" fxLayoutAlign="stretch" fxLayout="column"
                        style=" margin-top: 4em;">

                        <mat-grid-list cols="2" rowHeight="5em">
                            <mat-grid-tile>
                                <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
                                    <mat-label>AttributeGroup</mat-label>
                                    <mat-select required ngModel name="attributeGroup" formControlName="attributeGroup"
                                        (selectionChange)="getAttributes($event, i , j)">
                                        <mat-option *ngFor="let attributeGroup of AttributeGroups"
                                            [value]="attributeGroup.id">{{ attributeGroup.attributeGroupName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="(varientAttribute.controls.attributeGroup.touched || varientAttribute.controls.attributeGroup.dirty ) && varientAttribute.controls.attributeGroup.errors?.required">
                                        AttributeGroup is required</mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile>
                                <mat-form-field appearance="fill" style="width: 100%;">
                                    <mat-label>Attribute</mat-label>
                                    <mat-select required ngModel name="attribute" formControlName="attribute">
                                        <mat-option *ngFor="let attribute of varientAttributes[i][j]"
                                            [value]="attribute.id">{{ attribute.attributeName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="(varientAttribute.controls.attribute.touched || varientAttribute.controls.attribute.dirty ) && varientAttribute.controls.attribute.errors?.required">
                                        Attribute is required</mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </form>
                </mat-card-content>
            </mat-card-content>

            <mat-grid-list cols="2" rowHeight="5em">
                <button mat-raised-button color="primary" class="buttom-button"> Save </button>
                <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
            </mat-grid-list>
        </form>

    </mat-card-content>



    <div id="loader" *ngIf="flag">
        <mat-progress-spinner id="loader-com" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</mat-card>
<simple-notifications></simple-notifications>