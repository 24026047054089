<mat-card>
    <mat-card-header>
      <mat-card-title> Variants 
        <mat-form-field style="margin-left: 10px;" class="example-form-field">
          <mat-label>Search</mat-label>
          <input matInput type="text" [(ngModel)]="searchTerm" (keyup)="searchItems($event)">
          <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchData()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-card-title>
      <div style="position: absolute;right: 150px;font-size: larger;padding-top: 10px;">Total: {{total}}</div>
      <div fxFlex>
      </div>
      <button mat-raised-button style="width: 80px;height: 40px;padding: unset;" color="primary" [routerLink]="['..','variant-add']">
        Add Variant
      </button>
    </mat-card-header>
  
    <mat-card-content>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
  
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Variant Id</th>
          <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
        </ng-container>
  
        <!-- Position Column -->
        <ng-container matColumnDef="variantName">
          <th mat-header-cell *matHeaderCellDef>Variant Name</th>
          <td mat-cell *matCellDef="let element">{{ element.variantName }}</td>
        </ng-container>

        <ng-container matColumnDef="variantSlug">
          <th mat-header-cell *matHeaderCellDef>Variant Slug</th>
          <td mat-cell *matCellDef="let element">{{ element.variantSlug }}</td>
        </ng-container>

        <ng-container matColumnDef="variantPrice">
          <th mat-header-cell *matHeaderCellDef>Variant Price</th>
          <td mat-cell *matCellDef="let element">{{ element.variantPrice }}</td>
        </ng-container>
  
        <!-- Weight Column -->
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef>Timestamp</th>
          <td mat-cell *matCellDef="let element">{{ element.createdDate | date:'short' }}</td>
        </ng-container>
  
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element" style="display: flex;">
            <a
              style="margin:10px;"
              mat-raised-button
              [routerLink]="['..','variant-edit',element.id]"
              color="primary"
              >Edit</a
            >
  
            <!-- <button
            style="margin:10px;"
              mat-raised-button
              color="secondary"
              (click)="removeClick(element.id)"
            >
              Delete
            </button> -->
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      
    </mat-card-content>
    
  </mat-card>
  <simple-notifications></simple-notifications>
  