<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
    <img
      style="
      height: 2.1em;"
      src="/assets/images/KLIcon.png"
      alt=""
    />
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
<!--        <li>-->
<!--          <button mat-icon-button><mat-icon>settings</mat-icon></button>-->
<!--        </li>-->
<!--        <li>-->
<!--          <button mat-icon-button>-->
<!--            <mat-icon>help_outline</mat-icon>-->
<!--          </button>-->
<!--        </li>-->
        <li>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>person_outline</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['user-profile']">
              <mat-icon>perm_identity</mat-icon>
              Profile
            </button>
            <button mat-menu-item (click)="signOut()">
              <mat-icon>exit_to_app</mat-icon>
              Sign Out
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
