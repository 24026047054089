
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DefaultModule } from './layouts/default/default.module';

import { LoginComponent } from 'src/app/modules/login/login.component';
import { QuillModule } from 'ngx-quill';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TranslateModule } from '@ngx-translate/core';
import { NewsCategoryComponent } from './modules/news-category/news-category.component';
import { NewsCategoryAddComponent } from './modules/news-category/news-category-add/news-category-add.component';
import { CategoryComponent } from './modules/category/category.component';
import { CategoryAddComponent } from './modules/category/category-add/category-add.component';
// import { MatFileUploadModule } from 'mat-file-upload';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { JobComponent } from './modules/job/job.component';
import { JobAddComponent } from './modules/job/job-add/job-add.component';
import { ProductComponent } from './modules/product/product.component';
import { AttributeGroupComponent } from './modules/product/attribute-group/attribute-group.component';
import { AttributeComponent } from './modules/product/attribute/attribute.component';
import { AttributeGroupAddComponent } from './modules/product/attribute-group/attribute-group-add/attribute-group-add.component';
import { AttributeAddComponent } from './modules/product/attribute/attribute-add/attribute-add.component';
import { ProdcutAddComponent } from './modules/product/prodcut-add/prodcut-add.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { OrderComponent } from './modules/order/order.component';
import { OrderEditComponent } from './modules/order/order-edit/order-edit.component';
import { ProductEditComponent } from './modules/product/product-edit/product-edit.component';
import { VarientListComponent } from './modules/product/varient-list/varient-list.component';
import { VarientEditComponent } from './modules/product/varient-list/varient-edit/varient-edit.component';
import { MatTreeModule } from '@angular/material/tree';
import { StateComponent } from './modules/state/state.component';
import { CityComponent } from './modules/city/city.component';
import { CityAddComponent } from './modules/city/city-add/city-add.component';
import { StateAddComponent } from './modules/state/state-add/state-add.component';
import { ConfirmDialogComponent } from './modules/confirm-dialog/confirm-dialog.component';
import { SettingComponent } from './modules/setting/setting.component';


@NgModule({
  declarations: [AppComponent,
    LoginComponent,
    NewsCategoryComponent,
    NewsCategoryAddComponent,
    CategoryComponent,
    CategoryAddComponent,
    JobComponent,
    JobAddComponent,
    ProductComponent,
    AttributeGroupComponent,
    AttributeComponent,
    AttributeGroupAddComponent,
    AttributeAddComponent,
    ProdcutAddComponent,
    UserProfileComponent,
    OrderComponent,
    OrderEditComponent,
    ProductEditComponent,
    VarientListComponent,
    VarientEditComponent,
    // StateComponent,
    CityComponent,
    CityAddComponent,
    StateComponent,
    StateAddComponent,
    ConfirmDialogComponent,
    SettingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DefaultModule,
    FlexLayoutModule,

    MatTreeModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot(),
    QuillModule.forRoot(),
    MatProgressSpinnerModule,
    // MatFileUploadModule,
    MatGridListModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    CKEditorModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
