<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>
<div mat-dialog-actions *ngIf="!value">
  <button mat-button (click)="onDismiss()">No</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
</div>

<div *ngIf="value">
  <div mat-dialog-content>
    <p>{{message}}</p>
    <mat-form-field>
      <input matInput [(ngModel)]="value">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onDismiss()">No Thanks</button>
    <button mat-raised-button color="primary" (click)="onEdit(value)">Edit</button>
  </div>
</div>