<mat-card>
    <mat-card-title>Product Category</mat-card-title>
    <mat-card-content>
      <form [formGroup]="addNewsForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">
  
        <mat-form-field class="example-full-width">
          <mat-label>Category Name</mat-label>
          <input matInput placeholder="Category Name" required ngModel name="categoryName" formControlName="categoryName" />
          <mat-error *ngIf="(newsFormControl.categoryName.touched || newsFormControl.categoryName.dirty) && newsFormControl.categoryName.errors?.required">Category Name is required</mat-error>
        </mat-form-field>
  
       <mat-form-field class="example-full-width">
          <mat-label>Category Short Description</mat-label>
          <input matInput placeholder="Short Description" required ngModel name="categoryShortDescription" formControlName="categoryShortDescription" />
          <mat-error *ngIf="(newsFormControl.categoryShortDescription.touched || newsFormControl.categoryShortDescription.dirty) && newsFormControl.categoryShortDescription.errors?.required">Category Short Description is required</mat-error>
        </mat-form-field>
  
        <mat-label style="font-weight: 400;">Category Description</mat-label>
        <ckeditor required ngModel name="categoryDescription" formControlName="categoryDescription"></ckeditor>
        <!-- <quill-editor required [styles]="{ height: '235px' }" ngModel name="categoryDescription" formControlName="categoryDescription"></quill-editor> -->
        <mat-error *ngIf="(newsFormControl.categoryDescription.touched || newsFormControl.categoryDescription.dirty ) && newsFormControl.categoryDescription.errors?.required">Category Description is required</mat-error>
  
        <mat-form-field class="example-full-width">
          <mat-label>Category Image</mat-label>
          <input matInput placeholder="Category image" required ngModel name="categoryImage" formControlName="categoryImage"/>
          <mat-error *ngIf="(newsFormControl.categoryImage.touched || newsFormControl.categoryImage.dirty ) && newsFormControl.categoryImage.errors?.required">Category Image is required</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Category Slug</mat-label>
          <input matInput placeholder="Category Slug" required ngModel name="categorySlug" formControlName="categorySlug"/>
          <mat-error *ngIf="(newsFormControl.categorySlug.touched || newsFormControl.categorySlug.dirty ) && newsFormControl.categorySlug.errors?.required">Category Slug is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Parent Category</mat-label>
          <mat-select ngModel name="parentCategory" formControlName="parentCategory">
            <mat-option *ngFor="let category of Categories" [value]="category.id">{{
              category.categoryName
            }}</mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="(newsFormControl.category.touched || newsFormControl.category.dirty ) && newsFormControl.category.errors?.required">Category is required</mat-error> -->
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Order</mat-label>
          <input matInput placeholder="Category Slug" required ngModel name="order" formControlName="order"/>
          <mat-error *ngIf="(newsFormControl.order.touched || newsFormControl.order.dirty ) && newsFormControl.order.errors?.required">Order is required</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" *ngFor="let item of languageFields | keyvalue">
          <mat-label>{{ item.value | titlecase}}</mat-label>
          <input matInput placeholder="{{item.value | titlecase}}" required ngModel name="{{item.key}}" formControlName="{{item.key}}"/>
        </mat-form-field>
  
        <button mat-raised-button color="primary" class="buttom-button">
          Save
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <simple-notifications></simple-notifications>
  