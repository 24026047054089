<mat-card>
    <mat-card-title>Job Post</mat-card-title>
    <mat-card-content>
      <form [formGroup]="addJobForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">
  
        <mat-form-field class="example-full-width">
          <mat-label>Post</mat-label>
          <input matInput placeholder="Post" required ngModel name="post" formControlName="post" />
          <mat-error *ngIf="(jobFormControl.post.touched || jobFormControl.post.dirty) && jobFormControl.post.errors?.required">Post is required</mat-error>
        </mat-form-field>
  
       <mat-form-field class="example-full-width">
          <mat-label>Recruitment Board</mat-label>
          <input matInput placeholder="Recruitment Board" required ngModel name="recBoard" formControlName="recBoard" />
          <mat-error *ngIf="(jobFormControl.recBoard.touched || jobFormControl.recBoard.dirty) && jobFormControl.recBoard.errors?.required">Recruitment Board is required</mat-error>
        </mat-form-field>

  
        <!-- <mat-form-field class="example-full-width">
          <mat-label>Qualification</mat-label>
          <input matInput placeholder="Qualification" required ngModel name="qualification" formControlName="qualification"/>
          <mat-error *ngIf="(jobFormControl.qualification.touched || jobFormControl.qualification.dirty ) && jobFormControl.qualification.errors?.required">Qualification is required</mat-error>
        </mat-form-field> -->

        <!-- <mat-form-field class="example-full-width">
          <mat-label>Link</mat-label>
          <input matInput placeholder="Link" required ngModel name="link" formControlName="link"/>
          <mat-error *ngIf="(jobFormControl.link.touched || jobFormControl.link.dirty ) && jobFormControl.link.errors?.required">Category Slug is required</mat-error>
        </mat-form-field> -->

        <mat-grid-list cols="2" rowHeight="5em">
          <mat-grid-tile>
            <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
              <mat-label>Qualification</mat-label>
          <input matInput placeholder="Qualification" required ngModel name="qualification" formControlName="qualification"/>
          <mat-error *ngIf="(jobFormControl.qualification.touched || jobFormControl.qualification.dirty ) && jobFormControl.qualification.errors?.required">Qualification is required</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>State</mat-label>
              <mat-select required ngModel name="stateId" formControlName="stateId">
                <mat-option *ngFor="let state of States" [value]="state.id">{{
                  state.name
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="(jobFormControl.stateId.touched || jobFormControl.stateId.dirty ) && jobFormControl.stateId.errors?.required">State is required</mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>


        <mat-grid-list cols="3" rowHeight="5em">
          <mat-grid-tile>
            <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
              <mat-label>Link</mat-label>
              <input matInput placeholder="Link" required ngModel name="link" formControlName="link" style="margin-bottom: 0.8em;"/>
              <mat-error *ngIf="(jobFormControl.link.touched || jobFormControl.link.dirty ) && jobFormControl.link.errors?.required">Link is required</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Last Date</mat-label>
          <input matInput [min]="minDate" [matDatepicker]="picker" required ngModel name="lastDate" [formControl]="jobFormControl.lastDate"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="(jobFormControl.lastDate.touched || jobFormControl.lastDate.dirty ) && jobFormControl.lastDate.errors?.required">Last Date is required</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <section class="example-section" style="width: 50%;">
              <mat-checkbox style="margin: 10px;" color="primary"class="example-margin" ngModel name="isPublished" formControlName="isPublished">Is Published</mat-checkbox>
            </section>
          </mat-grid-tile>
        </mat-grid-list>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Last Date</mat-label>
          <input matInput [min]="minDate" [matDatepicker]="picker" required ngModel name="lastDate" [formControl]="jobFormControl.lastDate"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="(jobFormControl.lastDate.touched || jobFormControl.lastDate.dirty ) && jobFormControl.lastDate.errors?.required">Last Date is required</mat-error>
        </mat-form-field> -->

        <mat-grid-list cols="2" rowHeight="5em">
          <button mat-raised-button color="primary" class="buttom-button"> Save </button>
          <button mat-raised-button color="primary" class="buttom-button" (click) = "onCancel()"> Cancel </button>
        </mat-grid-list>
      </form>
    </mat-card-content>
  </mat-card>
  <simple-notifications></simple-notifications>
