<mat-card>
    <mat-card-title>State</mat-card-title>
    <mat-card-content>
        <form [formGroup]="addStateForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch"
            fxLayout="column">

            <mat-form-field class="example-full-width">
                <mat-label>State</mat-label>
                <input matInput placeholder="State" required ngModel name="name" formControlName="name" />
                <mat-error
                    *ngIf="(stateFormControl.name.touched || stateFormControl.name.dirty) && stateFormControl.name.errors?.required">
                    State Name is required</mat-error>
            </mat-form-field>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Hindi</mat-label>
                        <input matInput placeholder="Hindi" ngModel name="name_hi" formControlName="name_hi" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Gujarati</mat-label>
                        <input matInput placeholder="Gujarati" ngModel name="name_gu" formControlName="name_gu" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>Country</mat-label>
                        <mat-select required ngModel name="Country" formControlName="Country">
                            <mat-option *ngFor="let Country of Countries" [value]="Country.id">{{
                                Country.name
                                }}</mat-option>
                        </mat-select>
                        <!-- <mat-error
                            *ngIf="(stateFormControl.Country.touched || stateFormControl.Country.dirty ) && stateFormControl.Country.errors?.required">
                            Country is required</mat-error> -->
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <section class="example-section" style="width: 100%;">
                        <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel
                            name="is_active" formControlName="is_active">
                            Is Active
                        </mat-checkbox>
                    </section>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <button mat-raised-button color="primary" class="buttom-button"> Save </button>
                <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
            </mat-grid-list>
        </form>
    </mat-card-content>
</mat-card>
<simple-notifications></simple-notifications>