<mat-card>
    <mat-card-title>City</mat-card-title>
    <mat-card-content>
        <form [formGroup]="addCityForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch"
            fxLayout="column">

            <mat-form-field class="example-full-width">
                <mat-label>City</mat-label>
                <input matInput placeholder="city" required ngModel name="city" formControlName="city" />
                <mat-error
                    *ngIf="(cityFormControl.city.touched || cityFormControl.city.dirty) && cityFormControl.city.errors?.required">
                    city is required</mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Code</mat-label>
                <input matInput placeholder="code" ngModel name="code" formControlName="code" />
            </mat-form-field>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>taluka</mat-label>
                        <input matInput placeholder="taluka" ngModel name="taluka" formControlName="taluka" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>State</mat-label>
                        <mat-select required ngModel name="State" formControlName="State">
                            <mat-option *ngFor="let state of States" [value]="state.id">{{
                                state.name
                                }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="(cityFormControl.State.touched || cityFormControl.State.dirty ) && cityFormControl.State.errors?.required">
                            State is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Hindi</mat-label>
                        <input matInput placeholder="Hindi" ngModel name="city_hi" formControlName="city_hi" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%; margin-right: 1em;">
                        <mat-label>Gujarati</mat-label>
                        <input matInput placeholder="Gujarati" ngModel name="city_gu" formControlName="city_gu" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <section class="example-section" style="width: 100%;">
                        <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel
                            name="is_active" formControlName="is_active">
                            Is Active
                        </mat-checkbox>
                    </section>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
                <button mat-raised-button color="primary" class="buttom-button"> Save </button>
                <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
            </mat-grid-list>
        </form>
    </mat-card-content>
</mat-card>
<simple-notifications></simple-notifications>