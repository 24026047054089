import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-attribute-group-add',
  templateUrl: './attribute-group-add.component.html',
  styleUrls: ['./attribute-group-add.component.css']
})
export class AttributeGroupAddComponent implements OnInit {
  public addAttributeGroupNameForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  attr_group_id:string = null;
  minDate = new Date();



  States: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.addAttributeGroupNameForm = new FormGroup({
      attributeGroupName: new FormControl('', [Validators.required]),
    });


    this.attr_group_id = this.actRoute.snapshot.params.id;
    if(this.attr_group_id){
      this.http.get<any>(environment.apiBaseURL+'/product/attribute-group/'+this.attr_group_id,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data[0];
          this.addAttributeGroupNameForm = new FormGroup({
            attributeGroupName: new FormControl(data.attributeGroupName || "", [Validators.required]),
          });

        },
        error: error => {
          this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }
  }

  get attributeGroupFormControl() {
    return this.addAttributeGroupNameForm.controls;
  }

  ngOnInit(): void {}

  onSubmit(){
    if (this.addAttributeGroupNameForm.valid) {
      // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.addAttributeGroupNameForm.value);

      let data = this.addAttributeGroupNameForm.value;
      let dataToSend = {
        "attributeGroupName": data.attributeGroupName || "",
      }

    
      if(this.attr_group_id){
        this.http.put(environment.apiBaseURL+'/product/attribute-group/'+this.attr_group_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.router.navigate(['product','attribute-group']);
            this.notifications.create("Success",
              "Attribute Group updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else{
        this.http.post(environment.apiBaseURL+'/product/attribute-group',dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            this.router.navigate(['product','attribute-group']);
            this.notifications.create("Success",
              "Attribute Group Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }
}
