import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  public userProfileForm: FormGroup;
  access_token = localStorage.getItem('access_token')

  /**
   * createdDate: "2021-01-06T00:52:45.671Z"
    email: "admin@khakiwala.com"
    firebaseId: null
    fullName: "P S"
    id: 1
    is_delete: false
    mobile: "9408963408"
    otp: "999999"
    password: "a29a8b23be162a9f0e9e25319bac5b9f"
    profile: "admin"
    updatedDate: "2021-03-03T15:28:35.000Z"
    version: 0

   */

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.userProfileForm = new FormGroup({
      userEmail: new FormControl('', [Validators.required, Validators.email]),
      fullName: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
    });
    
    this.http.get<any>(environment.apiBaseURL + '/user/profile', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (data) => {

        this.userProfileForm = new FormGroup({
          userEmail: new FormControl(data.email, [Validators.required, Validators.email]),
          fullName: new FormControl(data.fullName, [Validators.required]),
          mobile: new FormControl(data.mobile, [Validators.required]),
        });

      },
      error: error => {
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })


  }

  get userFormControl() {
    return this.userProfileForm.controls;
  }

  // getUserProfile() {
  //   const url = `${environment.apiBaseURL}/user/profile`;
  //   this.http.get<any>(url, {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer ' + this.access_token
  //     })
  //   }).subscribe({
  //     next: data => {
  //       if (data.data) {
  //         this.userdata = data;
  //       }
  //     },
  //     error: error => {
  //       this.userdata = null;
  //     }
  //   })
  // }


  ngOnInit(): void { }

  onSubmit() {
  //   if (this.userProfileForm.valid) {
  //     // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
  //     console.table(this.userProfileForm.value);

  //     let data = this.userProfileForm.value;
  //     let dataToSend = {
  //       "categoryName": data.categoryName || "",
  //       "categoryShortDescription": data.categoryShortDescription || "",
  //       "categoryDescription": data.categoryDescription || "",
  //       "categoryImage": data.categoryImage || "",
  //       "categorySlug": data.categorySlug || "",
  //       "parent": data.parentCategory || null,
  //       "order": data.order || null,
  //     }

  //     let additionalCategoryName = {}

  //     for (var key in this.languageFields) {
  //       if (this.languageFields.hasOwnProperty(key)) {
  //         additionalCategoryName[key] = data[key] || '';
  //       }
  //     }

  //     dataToSend['additionalCategoryName'] = additionalCategoryName;

  //     if (this.user_id) {
  //       this.http.put(environment.apiBaseURL + '/category/' + this.user_id, dataToSend, {
  //         headers: new HttpHeaders({
  //           'Authorization': 'Bearer ' + this.access_token
  //         })
  //       }).subscribe({
  //         next: (data) => {
  //           this.router.navigate(['category']);
  //         },
  //         error: error => {
  //           this.notifications.create("Error",
  //             "Something goes wrong!. Please try again later",
  //             NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  //         }
  //       })
  //     }
  //     else {
  //       this.http.post(environment.apiBaseURL + '/category', dataToSend, {
  //         headers: new HttpHeaders({
  //           'Authorization': 'Bearer ' + this.access_token
  //         })
  //       }).subscribe({
  //         next: (response) => {
  //           this.router.navigate(['category']);
  //         },
  //         error: (error) => {
  //           this.notifications.create("Error",
  //             "Something goes wrong!. Please try again later",
  //             NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  //         }
  //       })
  //     }
  //   }
  }
}

