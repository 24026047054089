import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';

import { QuillModule } from 'ngx-quill';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { NewsComponent } from 'src/app/modules/news/news.component';
import { NewAddComponent } from 'src/app/modules/news/new-add/new-add.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatFileUploadModule } from 'mat-file-upload';

import {MatGridListModule} from '@angular/material/grid-list';
import { CKEditorModule } from 'ckeditor4-angular';


@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    NewsComponent,
    NewAddComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    QuillModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFileUploadModule,
    MatGridListModule,
    // MatDialogModule
    CKEditorModule
  ],
})
export class DefaultModule {}
