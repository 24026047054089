import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavService } from 'src/app/shared/components/sidebar/nav.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css'],
})
export class DefaultComponent implements OnInit {
  @ViewChild('appDrawer') appDrawer: ElementRef;
  sideBarOpen = true;

  constructor(private navService: NavService) {}

  ngOnInit(): void {}

  sideBarToggle() {
    console.log("@@@ : ", this.sideBarOpen);
    this.sideBarOpen = !this.sideBarOpen;
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }
}
