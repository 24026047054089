import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
// import CircularProgress from '@material-ui/core/CircularProgress';


const sortDescriptionLength = 100;
@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {
  public addProductForm: FormGroup;
  
  access_token = localStorage.getItem('access_token')
  product_id:string = null;
  file=null;
  Categories: string[] = [];
  TaxSlab: String[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService) {
    this.addProductForm = new FormGroup({
      productName: new FormControl('', [Validators.required]),
      // productSKU: new FormControl('', [Validators.required]),
      // productPrice: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      // productMRP: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      // productDiscount: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),

      productShortDescription: new FormControl('', [Validators.required]),
      productDescription: new FormControl('', [Validators.required]),
      category: new FormControl('' , [Validators.required]),
      taxSlab: new FormControl('' , [Validators.required]),

      productThumb: new FormControl('', [Validators.required]),
      productSlug: new FormControl('', [Validators.required]),
      stockStatus: new FormControl(false),
    });

    this.product_id = this.actRoute.snapshot.params.id;
    if(this.product_id){
      this.http.get<any>(environment.apiBaseURL+'/product/'+this.product_id,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({next: data => {
        data = data.data
        
        this.addProductForm = new FormGroup({
          productName: new FormControl(data.productName, [Validators.required]),
          // productSKU: new FormControl('', [Validators.required]),
          // productPrice: new FormControl(data.productPrice, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
          // productMRP: new FormControl(data.productMRP, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
          // productDiscount: new FormControl(data.productDiscount, [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
    
          productShortDescription: new FormControl(data.productShortDescription, [Validators.required]),
          productDescription: new FormControl(data.productDescription, [Validators.required]),
          category: new FormControl(data.category ? data.category.id : {}, [Validators.required]),
          taxSlab: new FormControl(data.taxSlab ? data.taxSlab.id : [], [Validators.required]),
          productThumb: new FormControl(data.productThumb, [Validators.required]),
          productSlug: new FormControl(data.productSlug, [Validators.required]),
          stockStatus: new FormControl(data.stockStatus || false),
        });
      }
        ,error: err => {
          this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }})
    }
    else{
      this.router.navigate(['product']);
    }

    let cat = this.http.get<any>(environment.apiBaseURL + '/category', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });
    let txtSlub = this.http.get<any>(environment.apiBaseURL + '/tax', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });

    forkJoin([cat, txtSlub]).subscribe({
      next: response => {
        this.Categories = response[0].data;
        this.TaxSlab = response[1].data;
      },
      error: err => {
        this.Categories = [];
        this.TaxSlab = [];
      }
    })

  }

  onUploadClicked(index = null){
    let formData = new FormData();
    formData.append('file', this.file);
    this.http.post(environment.imageBaseURL,formData,{
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + environment.imageToken
      })
    }).subscribe({
      next: (response) => {
        if(response['statusCode'] && response['statusCode']==200){
          this.addProductForm.patchValue({'productThumb': 'http://api.khakiwala.com:8080/'+response['data']});
        }
      },
      error: (error) =>{
        this.notifications.create("Error",
        "Something goes wrong!. Please try again later",
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  onSelectedFilesChanged(data){
    if(data && data[0]){
      this.file = data[0];
    }
  }

  get newsFormControl() {
    return this.addProductForm.controls;
  }

  ngOnInit(): void {}

  onSubmit(){

    if (this.addProductForm.valid) {
    //   // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
    //   console.table(this.addProductForm.value);
      let data = this.addProductForm.value;

      let dataToSend = {
        "productName": data.productName || "",
        // "productSKU": data.productSKU || "",
        // "productPrice": Number(data.productPrice) || 0,
        // "productMRP": Number(data.productMRP) || 0,
        // "productDiscount": Number(data.productDiscount) || 0,
        "productShortDescription": data.productShortDescription || "",
        "productDescription": data.productDescription ||"",
        "productThumb": data.productThumb || "",
        "productSlug": data.productSlug || "",
        "category": data.category || 0,
        "taxSlab": data.taxSlab || 0,
        "stockStatus": data.stockStatus || false,
      }

      console.log("data : ",dataToSend);

      if(this.product_id){
        this.http.put(environment.apiBaseURL+'/product/'+this.product_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: async (data) => {
            
            this.notifications.create("Success",
              "Product successfully updated",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            // this.getItems();
            this.router.navigate(['product']);
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }

  onCancel() {
    this.router.navigate(['product']);
  }

}



