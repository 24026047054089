import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog'

export interface Variant {
  id: number;
  variantName: string;
  variantSlug: string;
  variantPrice: string;
  createdDate: string;
  type: string
}

const ELEMENT_DATA: Variant[] = [];
const countManage = {
  limit: 100,
  offsef: 0,
}

@Component({
  selector: 'app-varient-list',
  templateUrl: './varient-list.component.html',
  styleUrls: ['./varient-list.component.css']
})
export class VarientListComponent implements OnInit {
  product_id = null;

  total = 0;
  searchTerm = '';
  numberOfRecords = 100;
  searchSub: Subscription;
  showSpinner = false;
  displayedColumns: string[] = [
    'id',
    'variantName',
    'variantSlug',
    'variantPrice',
    'createdDate',
    'delete',
  ];
  dataSource = ELEMENT_DATA;
  access_token = localStorage.getItem('access_token')

  constructor(private router: Router, private http: HttpClient, private notifications: NotificationsService, private translate: TranslateService, private actRoute: ActivatedRoute, public dialog: MatDialog) {
    this.product_id = this.actRoute.snapshot.params.id;
    if(this.product_id){
      this.getItems();
    }
  }

  ngOnInit(): void {
    this.getItems();
  }

  AddProductClick() {
    this.router.navigate(['/product-add']);
  }

  clearSearchData(){
    this.searchTerm = '';
    this.getItems();

  }
  searchItems(event){
    if(this.searchTerm){
      this.getItems();
    }
  }
  getItems() {
    this.showSpinner = true;
    const url = `${environment.apiBaseURL}/product/${this.product_id}?limit=${countManage.limit}&offset=${countManage.offsef}&searchTerm=${this.searchTerm}`;
    this.http.get<any>(url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        data = data.data
        this.showSpinner = false;
        if (data && data.variant && data.variant.length) {
          this.dataSource = data.variant;
        }
        this.total = data.variant.length || 0;
      },
      error: error => {
        this.showSpinner = false;
        this.dataSource = [];
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  chnageRecords(event){
    this.numberOfRecords = parseInt(event.value);
    countManage.limit = parseInt(event.value);
    this.getItems();
  }

  getNewRecords(event){
    if(event == 'prev'){
      if((this.total >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)){
        countManage.offsef = countManage.offsef - countManage.limit;
        this.getItems();
      }
    }
    else{
      if(this.total > countManage.offsef + countManage.limit){
        countManage.offsef = countManage.offsef + countManage.limit;
        this.getItems();
      }
    }

  }
  removeClick(id) {
    const message = `Are you sure you want to delete?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showSpinner = true;
        this.http.delete(environment.apiBaseURL+'/product/'+id,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.showSpinner = false;
            this.notifications.create("Success",
              "Successfully removed",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            this.getItems();
          },
          error: error => {
            this.showSpinner = false;
            this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        });
      }
    });
  }
}

