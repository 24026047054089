<app-header (toggleSideBarForMe)="sideBarToggle()"></app-header>

<mat-drawer-container>
  <mat-drawer #appDrawer mode="side" [opened]="sideBarOpen">
    <app-sidebar></app-sidebar>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<!-- <mat-sidenav-container>
  <mat-sidenav mode="side" [opened]="sideBarOpen">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
  <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->

<app-footer></app-footer>
