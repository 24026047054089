<mat-grid-list cols="4" rowHeight="10em">
    <mat-grid-tile>
        <mat-card style="background-color: rgb(124, 164, 187);width: 100%;text-align: center;">
            <mat-icon >list_alt</mat-icon>
            <mat-card-title style="text-align: center;">News</mat-card-title>
            <mat-card-content>{{dataSource?.news}}</mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card style="background-color: rgb(106, 182, 116);width:100%;text-align: center;">
            <mat-icon >perm_identity</mat-icon>
            <mat-card-title style="text-align: center;">Users</mat-card-title>
            <mat-card-content>{{dataSource?.users}}</mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card style="background-color: rgb(207, 174, 89);width: 100%;text-align: center;">
            <mat-icon >reorder</mat-icon>
            <mat-card-title style="text-align: center;">Orders</mat-card-title>
            <mat-card-content>{{dataSource?.orders}}</mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card style="background-color: rgb(206, 77, 152);width:100%;text-align: center;">
            <mat-icon >fit_screen</mat-icon>
            <mat-card-title style="text-align: center;">Products</mat-card-title>
            <mat-card-content>{{dataSource?.products}}</mat-card-content>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
