import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface Setting {
  key: string;
  value: string;
}

const ELEMENT_DATA: Setting[] = [];
const countManage = {
  limit: 10,
  offsef: 0,
}
interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-state',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  total = 0;
  count = 0
  searchTerm = '';
  numberOfRecords = 10;
  searchSub: Subscription;
  showSpinner = false;
  displayedColumns: string[] = [
    'key',
    'value',
    'update'
  ];
  dataSource = ELEMENT_DATA;
  access_token = localStorage.getItem('access_token')

  constructor(private router: Router, private http: HttpClient, private notifications: NotificationsService, private translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getItems();
  }

  // AddSettingClick() {
  //   this.router.navigate(['/setting-add']);
  // }

  clearSearchData() {
    this.searchTerm = '';
    this.getItems();

  }

  searchItems(event) {
    countManage.offsef = 0;
    this.getItems();
  }

  getItems() {
    this.showSpinner = true;
    const url = `${environment.apiBaseURL}/setting?limit=${countManage.limit}&offset=${countManage.offsef}&searchTerm=${this.searchTerm}`;
    this.http.get<any>(url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        console.log(data);

        this.count = data.total;
        if (data.data) {
          this.showSpinner = false;
          this.dataSource = data.data;
          console.log(this.dataSource);

          this.total = data.data.length;
        } else {
          this.showSpinner = false;
          this.total = data.total || 0;
        }
      },
      error: error => {
        this.showSpinner = false;
        this.dataSource = [];
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  chnageRecords(event) {
    this.numberOfRecords = parseInt(event.value);
    countManage.limit = parseInt(event.value);
    this.getItems();
  }

  getNewRecords(event) {
    if (event == 'prev') {
      if ((this.count >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)) {
        countManage.offsef = countManage.offsef - countManage.limit;
        this.getItems();
      }
    }
    else {
      if (this.count >= countManage.offsef + countManage.limit) {
        countManage.offsef = countManage.offsef + countManage.limit;
        this.getItems();
      }
    }
  }

  editClick(data) {
    let finalData = { message: '', value: data.value }
    const dialogData = new ConfirmDialogModel("Edit Value", finalData);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showSpinner = true;
        this.http.put(environment.apiBaseURL + '/setting/' + data.id, { value: dialogResult.value }, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.showSpinner = false;
            this.notifications.create("Success",
              "News successfully updated",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            this.getItems();
          },
          error: error => {
            this.showSpinner = false;
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        });
      }
    });
  }
}
