import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-job-add',
  templateUrl: './job-add.component.html',
  styleUrls: ['./job-add.component.css']
})
export class JobAddComponent implements OnInit {
  public addJobForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  job_id:string = null;
  minDate = new Date();



  States: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.addJobForm = new FormGroup({
      post: new FormControl('', [Validators.required]),
      recBoard: new FormControl('', [Validators.required]),
      qualification: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required]),
      lastDate: new FormControl(new Date(), [Validators.required]),
      stateId: new FormControl('', [Validators.required]),
      isPublished: new FormControl(false),
    });


    this.job_id = this.actRoute.snapshot.params.id;
    if(this.job_id){
      this.http.get<any>(environment.apiBaseURL+'/job/'+this.job_id,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data.data
          
          this.addJobForm = new FormGroup({
            post: new FormControl(data.post || "", [Validators.required]),
            recBoard: new FormControl(data.recBoard || "", [Validators.required]),
            qualification: new FormControl(data.qualification || null, [Validators.required]),
            link: new FormControl(data.link || "", [Validators.required]),
            lastDate: new FormControl(data.lastDate || "", [Validators.required]),
            stateId: new FormControl(data.stateId || "", [Validators.required]),
            isPublished: new FormControl(data.isPublished || false),
          });

        },
        error: error => {
          this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }

    this.http.get<any>(environment.apiBaseURL+'/geocoding/state/41',{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        response = response.data
        this.States = response;
        console.log('States: ', this.States);
      },
      error: error => {
        this.States = [];
      }
    })
  }

  get jobFormControl() {
    return this.addJobForm.controls;
  }

  ngOnInit(): void {}

  onSubmit(){
    if (this.addJobForm.valid) {
      // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.addJobForm.value);

      let data = this.addJobForm.value;
      let dataToSend = {
        "post": data.post || "",
        "recBoard": data.recBoard || "",
        "qualification": data.qualification || "",
        "link": data.link || "",
        "lastDate": data.lastDate || new Date(),
        "stateId": data.stateId || null,
        "isPublished": data.isPublished || false
      }

    
      if(this.job_id){
        this.http.put(environment.apiBaseURL+'/job/'+this.job_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.router.navigate(['job']);
            this.notifications.create("Success",
              "Job Updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else{
        this.http.post(environment.apiBaseURL+'/job',dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            this.router.navigate(['job']);
            this.notifications.create("Success",
              "Job Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }

  onCancel() {
    this.router.navigate(['job']);
  }
}
