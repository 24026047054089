import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
// import CircularProgress from '@material-ui/core/CircularProgress';

interface NewsType {
  value: string;
  viewValue: string;
}

const sortDescriptionLength = 100;

@Component({
  selector: 'app-prodcut-add',
  templateUrl: './prodcut-add.component.html',
  styleUrls: ['./prodcut-add.component.css']
})
export class ProdcutAddComponent implements OnInit {
  selectedType="Other News"
  public addProductForm: FormGroup;
  public varientForms: FormGroup[] = [];
  public varientAttributesForms: FormGroup[][] = [];
  public varientAttributes = [[]];
  access_token = localStorage.getItem('access_token')
  product_id:string = null;
  flag = false;
  file=null;
  varientBox = null;

  // @ViewChild('varientBox') varientBox: ElementRef;

  newsType: NewsType[] = [
    { value: 'Khakiwala Original', viewValue: 'Khakiwala Original' },
    { value: 'Other News', viewValue: 'Other News' },
  ];


  AttributeGroups: string[] = [];
  Attributes: string[] = [];
  Categories: string[] = [];
  TaxSlab: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private elRef:ElementRef) {
    this.addProductForm = new FormGroup({
      productName: new FormControl('', [Validators.required]),
      // productSKU: new FormControl('', [Validators.required]),
      productPrice: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      productMRP: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      productDiscount: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),

      productShortDescription: new FormControl('', [Validators.required]),
      productDescription: new FormControl('', [Validators.required]),

      productThumb: new FormControl('', [Validators.required]),
      productSlug: new FormControl('', [Validators.required]),
      category: new FormControl('' , [Validators.required]),
      taxSlab: new FormControl('', [Validators.required]),
      stockStatus: new FormControl(false),
    });
    this.AddVarientClick();

    // this.addProductForm.controls.description.setValidators([Validators.required]);


    this.product_id = this.actRoute.snapshot.params.id;
    if(this.product_id){
      this.http.get<any>(environment.apiBaseURL+'/news/'+this.product_id,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data.data;
          // this.addProductForm?.value?.newsType != 'Other News' ? [] : [Validators.required]
          this.addProductForm = new FormGroup({
            title: new FormControl(data.title || "", [Validators.required]),
            shortDescription: new FormControl(data.shortDescription || "", [Validators.required, Validators.maxLength(sortDescriptionLength)]),
            newsType: new FormControl(data.type || null, [Validators.required]),
            description: new FormControl(data.description || "", data.type != 'Other News' ? [] : [Validators.required]),
            thumbnail: new FormControl(data.thumbnail || "", [Validators.required]),
            tags: new FormControl(data.tags || "", [Validators.required]),
            source: new FormControl(data.source || "", [Validators.required]),
            sourceLink: new FormControl(data.sourcelink || "", [Validators.required]),
            language: new FormControl(data.language || null, [Validators.required]),
            publishDate: new FormControl(data.publishdate ? data.publishdate : null),
            category: new FormControl(data.category ? data.category.id : [], [Validators.required]),
            state: new FormControl(data.state_id || null , [Validators.required]),
            city: new FormControl(data.city_id || null, [Validators.required]),
            priority: new FormControl(data.priority || null, [Validators.required]),
            isPublished: new FormControl(data.isPublished || false),
            isFeatured: new FormControl(data.isFeatured || false),
          });

          this.changeNewsType({value: data.type});

          this.http.get<any>(environment.apiBaseURL+'/geocoding/city/'+data.state_id,{
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this.access_token
            })
          }).subscribe({
            next: (response) => {
              this.Attributes = response;
            },
            error: err => {
              this.Attributes = [];
            }
          })
        },
        error: error => {
          this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }

    let attrGrp = this.http.get<any>(environment.apiBaseURL+'/product/attribute-group',{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });
    let cat = this.http.get<any>(environment.apiBaseURL + '/category', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });
    let txtSlub = this.http.get<any>(environment.apiBaseURL + '/tax', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });

    forkJoin([attrGrp,cat,txtSlub]).subscribe({
      next: response => {
        this.AttributeGroups = response[0];
        this.Categories = response[1].data;
        this.TaxSlab = response[2].data;
      },
      error: err => {
        this.AttributeGroups = [];
        this.Categories = [];
        this.TaxSlab = [];
      }
    })
  }

  changeNewsType(data){
    if(data.value == "Khakiwala Original"){
      this.addProductForm.controls.description.setValidators([Validators.required]);
      this.addProductForm.controls.source.setValidators([]);
      this.addProductForm.controls.sourceLink.setValidators([]);
    }
    else{
      this.addProductForm.controls.description.setValidators([]);
      this.addProductForm.controls.source.setValidators([Validators.required]);
      this.addProductForm.controls.sourceLink.setValidators([Validators.required]);
    }
    this.addProductForm.controls.description.updateValueAndValidity();
  }

  onUploadClicked(index = null){
    let formData = new FormData();
    formData.append('file', this.file);
    this.http.post(environment.imageBaseURL,formData,{
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + environment.imageToken
      })
    }).subscribe({
      next: (response) => {
        if(response['statusCode'] && response['statusCode']==200){
          if(index==null){
            this.addProductForm.patchValue({'productThumb': 'http://api.khakiwala.com:8080/'+response['data']});
          }
          else{
            this.varientForms[index].patchValue({'varientThumb': 'http://api.khakiwala.com:8080/'+response['data']})
          }
        }
      },
      error: (error) =>{
        this.notifications.create("Error",
        "Something goes wrong!. Please try again later",
        NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  onSelectedFilesChanged(data){
    if(data && data[0]){
      this.file = data[0];
    }
  }

  getAttributes(event, parent, child){
    this.flag = true;
    this.http.get<any>(environment.apiBaseURL+'/product/attribute-group/attributes/'+event.value,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        this.Attributes = response;
        this.varientAttributes[parent][child] = response;
        this.flag = false;
      },
      error: err => {
        this.Attributes = [];
        this.flag = false;
      }
    })
  }

  get newsFormControl() {
    return this.addProductForm.controls;
  }

  ngOnInit(): void {}

  onSubmit(){
    let varientValidation = this.varientForms.map(singleForm => { if(!singleForm.valid) return true; }).filter(item => item);
    let varientAttributesValidation = this.varientAttributesForms.map( singleVarient => {
      let singleVarientresult = singleVarient.map( singleAttributes => {
        if(!singleAttributes.valid) return true
      }).filter(item => item);

      if(singleVarientresult.length){
        return true;
      }
    }).filter(item => item);

    console.log("@@@ : ", varientValidation, varientAttributesValidation, this.addProductForm.valid)
    if (this.addProductForm.valid && varientValidation.length == 0 && varientAttributesValidation.length == 0) {
    //   // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
    //   console.table(this.addProductForm.value);
      let data = this.addProductForm.value;
      let varientData = [];
      let varientAttributesData = [];

      this.varientAttributesForms.forEach((item,index) => {
        varientAttributesData[index] = item.map(itemChild => {
          let singleFormData = itemChild.value;
          return {
            "attributeGroup": singleFormData.attributeGroup || 0,
            "attribute": singleFormData.attribute || 0
          }
        })
      })

      this.varientForms.forEach((item,index) => {
        let singleFormData  = item.value;
        varientData[index] = {
          "varientName": singleFormData.varientName || "",
          "varientThumb": singleFormData.varientThumb || "",
          "varientSlug": singleFormData.varientSlug || "",
          "varientMRP": Number(singleFormData.varientMRP) || 0,
          "varientPrice": Number(singleFormData.varientPrice) || 0,
          "varientDiscount": Number(singleFormData.varientDiscount) || 0,
          "varientSKU": singleFormData.varientSKU || "",
          "varientWeight": Number(singleFormData.varientWeight) || 0,
          "varientCountryOfOrigin": singleFormData.varientCountryOfOrigin || "",
          "stockStatus": singleFormData.stockStatus || false,
          "varientAttribute": varientAttributesData[index] || []
        }
      });
      let dataToSend = {
        "productName": data.productName || "",
        "productSKU": data.productSKU || "",
        "productPrice": Number(data.productPrice) || 0,
        "productMRP": Number(data.productMRP) || 0,
        "productDiscount": Number(data.productDiscount) || 0,
        "productShortDescription": data.productShortDescription || "",
        "productDescription": data.productDescription ||"",
        "productThumb": data.productThumb || "",
        "productSlug": data.productSlug || "",
        "category": data.category || 1,
        "taxSlab": data.taxSlab || 1,
        "varient": varientData || [],
        "stockStatus": data.stockStatus || false,
      }

      console.log("data : ",dataToSend);

      if(this.product_id){
        this.http.put(environment.apiBaseURL+'/product/'+this.product_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: async (data) => {
            
            this.notifications.create("Success",
              "Product successfully updated",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            // this.getItems();
            this.router.navigate(['product']);
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else{
        this.http.post(environment.apiBaseURL+'/product',dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: async (response) => {
            await this.notifications.create("Success",
              "Product successfully added",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            this.router.navigate(['product']);
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }

  AddVarientClick(){
    this.varientForms[this.varientForms.length] = new FormGroup({
      varientName: new FormControl('Hello', [Validators.required]),
      varientThumb: new FormControl('', [Validators.required]),
      varientSlug: new FormControl('', [Validators.required]),
      varientMRP: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      varientPrice: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      varientDiscount: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
      // varientSKU: new FormControl('', [Validators.required]),
      varientWeight: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
      varientQtys: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
      stockStatus: new FormControl(false),
    })

    console.log(this.varientForms);
  }

  removeVarient(index){
    this.varientForms.splice(index, 1);
    this.varientAttributesForms.splice(index,1);

    console.log("varientForms : ", this.varientForms);
    console.log("varientAttributesForms :", this.varientAttributesForms);
  }

  addAttributes(index){
    if(!this.varientAttributesForms[index]){
      this.varientAttributesForms[index] = [];

    }
    this.varientAttributesForms[index][this.varientAttributesForms[index].length] = new FormGroup({
      attributeGroup: new FormControl('',[Validators.required]),
      attribute: new FormControl('',[Validators.required]),
    })
    
  }

  removeAttributes(parentIndex, childIndex){
    this.varientAttributesForms[parentIndex].splice(childIndex,1);
  }

  onCancel() {
    this.router.navigate(['product']);
  }

}


