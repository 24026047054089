import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface DashData {
  news: number,
  users: number,
  orders: number,
  products: number
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  access_token = localStorage.getItem('access_token')
  dataSource: DashData;

  constructor(private http: HttpClient) {
    const url = `${environment.apiBaseURL}/dashboard`;
    this.http.get<any>(url,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        data = data.data
        if(data){
          this.dataSource = data;
        }
      },
      error: error => {
        this.dataSource = null;
      }
    })
   }

  ngOnInit(): void {
  }

}
