import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavService } from './nav.service';


interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}

// const TREE_DATA: EndNode[] = [
//   {
//     name: 'Dashboard',
//     url: "/",
//   }, 
//   {
//     name: 'News',
//     children:[
//       {
//         name: 'News',
//         url:"news"
//       },
//       {
//         name: "News Category",
//         url: "news-category"
//       }
//     ]
//   },
//   {
//     name: 'Job',
//     url: "job",
//   },
//   {
//     name: 'Prodcut',
//     url: "",
//     children:[
//       {
//         name: 'product',
//         url:"product"
//       },
//       {
//         name: "Product Category",
//         url: "category"
//       }
//     ]
//   },
//   {
//     name: 'Attribute Group',
//     url: "product/attribute-group",
//   },
//   {
//     name: 'Order',
//     url: "order",
//   },
//   {
//     name: 'Logout',
//     url: "",
//   },
// ];

/** Flat node with expandable and level information */
interface ExampleEndNode {
  expandable: boolean;
  name: string;
  url: string;
  level: number;
}


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidebarComponent implements OnInit {

  // private _transformer = (node: NavItem, level: number) => {
  //   return {
  //     expandable: !!node.children && node.children.length > 0,
  //     name: node.name,
  //     url: node.url,
  //     level: level,
  //   };
  // }
  navItems: NavItem[] = [
    {
      displayName: 'Dashboard',
      iconName: 'dashboard',
      route: '/',
    },
    {
      displayName: 'News',
      iconName: 'receipt',
      children: [
        {
          displayName: 'News',
          iconName: 'format_list_bulleted',
          route: 'news',
        },
        {
          displayName: 'News Category',
          iconName: 'ac_unit',
          route: 'news-category',
        }
      ]
    },
    {
      displayName: 'Job',
      iconName: 'group_work',
      route: 'job'
    },
    {
      displayName: 'Product',
      iconName: 'shopping_cart',
      children: [
        {
          displayName: 'Product',
          iconName: 'toys',
          route: 'product',
        },
        {
          displayName: 'Product Category',
          iconName: 'ac_unit',
          route: 'category',
        }
      ]
    },{
      displayName: 'Attribute Group',
      iconName: 'all_inclusive',
      route: 'product/attribute-group'
    },{
      displayName: 'Order',
      iconName: 'list_alt',
      route: 'order'
    },{
      displayName: 'State',
      iconName: 'dns',
      route: 'state'
    },{
      displayName: 'City',
      iconName: 'location_city',
      route: 'city'
    },
    {
      displayName: 'Setting',
      iconName: 'settings',
      route: 'setting'
    },{
      displayName: 'Logout',
      iconName: 'all_out',
    },
  ];

  // treeControl = new FlatTreeControl<ExampleEndNode>(
  //   node => node.level, node => node.expandable);

  // treeFlattener = new MatTreeFlattener(
  //   this._transformer, node => node.level, node => node.expandable, node => node.children);

  // dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private authService: AuthService, public navService: NavService,
    public router: Router) {
    // this.dataSource.data = TREE_DATA;
   }

  ngOnInit(): void {}

  // hasChild = (_: number, node: ExampleEndNode) => node.expandable;

  // navigate(node){
  //   if(node.name.toLowerCase() == "logout"){
  //     this.authService.signOut();
  //   }
  //   else{
  //     this.router.navigateByUrl(node.url);
  //   }
  // }

  logout(){
    this.authService.signOut();
  }

}
