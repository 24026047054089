import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
// import CircularProgress from '@material-ui/core/CircularProgress';

interface NewsType {
  value: string;
  viewValue: string;
}

interface Language {
  value: string;
  viewValue: string;
}

interface Priority {
  value: number;
  viewValue: string;
}

const sortDescriptionLength = 150;

@Component({
  selector: 'app-new-add',
  templateUrl: './new-add.component.html',
  styleUrls: ['./new-add.component.css'],
})
export class NewAddComponent implements OnInit {
  selectedType = "Other News"
  public addNewsForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  news_id: string = null;
  flag = false;
  file = null;
  uploadImage = null;
  imageUrl = null;
  videoSelected = true;
  imageUrlSelected = true;
  imageSelected = true;
  baseUrl = environment.apiBaseURL;

  newsType: NewsType[] = [
    { value: 'Khakiwala Original', viewValue: 'Khakiwala Original' },
    { value: 'Other News', viewValue: 'Other News' },
  ];

  languageType: Language[] = [
    { value: 'en', viewValue: 'English' },
    { value: 'gu', viewValue: 'Gujarati' },
    { value: 'hi', viewValue: 'Hindi' },
  ]

  priorityType: Priority[] = [
    { value: 1, viewValue: 'High' },
    { value: 2, viewValue: 'Medium' },
    { value: 3, viewValue: 'Low' },
  ]

  Categories: string[] = [];
  States: string[] = [];
  Cities: string[] = [];


  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService) {
    //const regImageUrl = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w =.-]*/?'
    const regImageUrl = '((http|https)://)?(www.)?[a-zA-Z0-9@:%._\\+~#?&^$!,_//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&^$!,_//=]*)'
    this.addNewsForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      shortDescription: new FormControl('', [Validators.required, Validators.maxLength(sortDescriptionLength)]),
      newsType: new FormControl('Other News', [Validators.required]),
      description: new FormControl(''),
      thumbnail: new FormControl('', [Validators.pattern(regImageUrl)]),
      // thumbnail: new FormControl('', [Validators.pattern(regImageUrl)]),
      tags: new FormControl('', [Validators.required]),
      source: new FormControl('', [Validators.required]),
      sourceLink: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      publishDate: new FormControl(new Date()),
      category: new FormControl([], [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      video_link: new FormControl(''),
      priority: new FormControl(null, [Validators.required]),
      isPublished: new FormControl(false),
      isFeatured: new FormControl(false),
      isVideoSource: new FormControl(false),
    });
    // this.addNewsForm.controls.description.setValidators([Validators.required]);


    this.news_id = this.actRoute.snapshot.params.id;
    if (this.news_id) {
      this.http.get<any>(this.baseUrl + '/news/' + this.news_id, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data.data
          
          // this.addNewsForm?.value?.newsType != 'Other News' ? [] : [Validators.required]
          this.addNewsForm = new FormGroup({
            title: new FormControl(data.title || "", [Validators.required]),
            shortDescription: new FormControl(data.shortDescription || "", [Validators.required, Validators.maxLength(sortDescriptionLength)]),
            newsType: new FormControl(data.type || null, [Validators.required]),
            description: new FormControl(data.description || "", data.type != 'Other News' ? [] : [Validators.required]),
            thumbnail: new FormControl(data.thumbnail || ""),
            tags: new FormControl(data.tags || "", [Validators.required]),
            source: new FormControl(data.source || "", [Validators.required]),
            sourceLink: new FormControl(data.sourcelink || "", [Validators.required]),
            language: new FormControl(data.language || null, [Validators.required]),
            publishDate: new FormControl(data.publishdate ? data.publishdate : null),
            category: new FormControl(data.category ? data.category.map(element => element.id) : [], [Validators.required]),
            state: new FormControl(data.state_id || null, [Validators.required]),
            city: new FormControl(data.city_id || null, [Validators.required]),
            video_link: new FormControl(data.video_link || null),
            priority: new FormControl(data.priority || null, [Validators.required]),
            isPublished: new FormControl(data.isPublished || false),
            isFeatured: new FormControl(data.isFeatured || false),
            isVideoSource: new FormControl(data.isVideoSource || false),
          });
          this.changeNewsType({ value: data.type });

          this.http.get<any>(this.baseUrl + '/geocoding/city/' + data.state_id, {
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this.access_token
            })
          }).subscribe({
            next: (response) => {
              response = response.data;
              this.Cities = response;
            },
            error: err => {
              this.Cities = [];
            }
          })
          this.imageExists(this.baseUrl + '/files/' + data.thumbnail).then(validUrl => {
            if (validUrl) {
              this.uploadImage = data.thumbnail
              this.imageUrl = this.baseUrl + '/files/' + data.thumbnail
              this.addNewsForm.patchValue({ 'thumbnail': "" });
            }
          })
        },
        error: error => {
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    } else {
      this.addNewsForm.patchValue({ 'isPublished': true });

    }

    let cat = this.http.get<any>(this.baseUrl + '/news-category', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });
    let states = this.http.get<any>(this.baseUrl + '/geocoding/state/41', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });


    forkJoin([cat, states]).subscribe({
      next: response => {
        this.Categories = response[0].data;
        this.States = response[1].data;
      },
      error: err => {
        this.Categories = [];
        this.States = [];
      }
    })
  }

  changeNewsType(data) {
    if (data.value == "Khakiwala Original") {
      this.addNewsForm.controls.description.setValidators([Validators.required]);
      this.addNewsForm.controls.source.setValidators([]);
      this.addNewsForm.controls.sourceLink.setValidators([]);
    }
    else {
      this.addNewsForm.controls.description.setValidators([]);
      this.addNewsForm.controls.source.setValidators([Validators.required]);
      this.addNewsForm.controls.sourceLink.setValidators([Validators.required]);
    }
    this.addNewsForm.controls.description.updateValueAndValidity();
  }

  imageExists(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function () { resolve(true); };
      img.onerror = function () { reject(false); };
      img.src = url;
      console.log(img);
    }).catch(() => {
      return false
    })
  }

  onChangeEvent($event) {
    if ($event.value) {
      this.file = ''
      switch ($event.name) {
        case 'video_link':
          this.videoSelected = true;
          this.imageUrlSelected = false;
          this.imageSelected = false;
          this.addNewsForm.patchValue({ 'thumbnail': "" });
          this.uploadImage = ''
          break;
        case 'thumbnail':
          this.videoSelected = false;
          this.imageUrlSelected = true;
          this.imageSelected = false;
          this.addNewsForm.patchValue({ 'video_link': "" });
          this.uploadImage = ''
          break;
        default:
          this.onDefaultImageSelection();
          break;
      }
    } else {
      this.videoSelected = true;
      this.imageUrlSelected = true;
      this.imageSelected = true;
    }
  }

  onUploadClicked(data) {
    let formData = new FormData();
    formData.append('file', this.file);
    this.http.post(this.baseUrl + "/files/upload", formData, {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        if (response) {
          if (response['filename'])
            this.uploadImage = response['filename']
          this.imageUrl = this.baseUrl + '/files/' + response['filename']
          this.videoSelected = false;
          this.imageUrlSelected = false;
          this.imageSelected = true;
          this.addNewsForm.patchValue({ 'thumbnail': "" });
          this.addNewsForm.patchValue({ 'video_link': "" });
        }
      },
      error: (error) => {
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  onDefaultImageSelection() {
    this.videoSelected = true;
    this.imageUrlSelected = true;
    this.imageSelected = true;
  }

  onRemoveImage() {
    let image = this.uploadImage;
    this.imageExists(this.baseUrl + '/files/' + this.uploadImage).then(validUrl => {
      if (validUrl) {
        this.http.get<any>(this.baseUrl + '/files/remove/' + image, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            console.log(response);
          },
          error: err => {
            console.log(err);

          }
        })
      }
    })
    this.file = ''
    this.uploadImage = ''
    this.onDefaultImageSelection();
  }

  onSelectedFilesChanged(imgFile) {
    this.file = ''
    this.uploadImage = null;
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.file = '';
      Array.from(imgFile.target.files).forEach((file: any) => {
        this.file = file;
      });
    }
  }

  getCities(event) {
    this.flag = true;
    this.http.get<any>(this.baseUrl + '/geocoding/city/' + event.value, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        response = response.data
        this.Cities = response;
        this.flag = false;
      },
      error: err => {
        this.Cities = [];
        this.flag = false;
      }
    })
  }

  get newsFormControl() {
    return this.addNewsForm.controls;
  }

  ngOnInit(): void { }

  onSubmit() {
    console.log("data : ", this.addNewsForm.value);

    // this.addNewsForm.value.append('thumbnail', this.file);
    if (this.addNewsForm.valid) {
      console.table(this.addNewsForm.value);
      let data = this.addNewsForm.value;
      if (data.thumbnail || this.uploadImage || data.video_link) {
        let dataToSend = {
          "title": data.title || "",
          "description": data.description || "",
          "thumbnail": data.thumbnail ? data.thumbnail : this.uploadImage ? this.uploadImage : "",
          "tags": data.tags || "",
          "publishdate": data.publishDate || new Date(),
          "source": data.source || "",
          "sourcelink": data.sourceLink || "",
          "isPublished": data.isPublished || false,
          "priority": data.priority,
          "language": data.language,
          "type": data.newsType,
          "category": data.category,
          "shortDescription": data.shortDescription,
          "isFeatured": data.isFeatured || false,
          "isVideoSource": data.isVideoSource || false,
          "state_id": data.state,
          "city_id": data.city,
          'video_link': data.video_link || "",
          "city": this.Cities.find(item => item['id'] == data.city)['city'],
          "state": this.States.find(item => item['id'] == data.state)['name']
        }
        console.log(dataToSend);
        if (this.news_id) {
          this.http.put(this.baseUrl + '/news/' + this.news_id, dataToSend, {
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this.access_token
            })
          }).subscribe({
            next: async (data) => {
              this.notifications.create("Success",
                "News successfully updated",
                NotificationType.Success, { timeOut: 3000, showProgressBar: true });
              // this.getItems();
              this.router.navigate(['news']);
            },
            error: error => {
              this.notifications.create("Error",
                "Something goes wrong!. Please try again later",
                NotificationType.Error, { timeOut: 3000, showProgressBar: true });
            }
          })
        }
        else {
          this.http.post(this.baseUrl + '/news', dataToSend, {
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this.access_token
            })
          }).subscribe({
            next: async (response) => {
              await this.notifications.create("Success",
                "News successfully added",
                NotificationType.Success, { timeOut: 3000, showProgressBar: true });
              this.router.navigate(['news']);
            },
            error: (error) => {
              this.notifications.create("Error",
                "Something goes wrong!. Please try again later",
                NotificationType.Error, { timeOut: 3000, showProgressBar: true });
            }
          })
        }
      } else {
        this.notifications.create("Error",
          "Select Image URL Or Video URL !!!",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    }
  }
  onCancel() {
    this.router.navigate(['news']);
  }

  onSourceLinkChange(event) {
    console.log("called")
    debugger;
    if (event.target.value.includes("youtube.com/watch")) {
      this.addNewsForm.controls.isVideoSource.setValue(true);
    }else{
      this.addNewsForm.controls.isVideoSource.setValue(false);
    }
  }
}
