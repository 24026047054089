import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-city-add',
  templateUrl: './city-add.component.html',
  styleUrls: ['./city-add.component.css']
})
export class CityAddComponent implements OnInit {
  public addCityForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  city_id: string = null;

  States: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.addCityForm = new FormGroup({
      city: new FormControl('', [Validators.required]),
      code: new FormControl(''),
      State: new FormControl('', [Validators.required]),
      taluka: new FormControl(''),
      is_active: new FormControl(true),
      city_hi: new FormControl(''),
      city_gu: new FormControl('')
    });


    this.city_id = this.actRoute.snapshot.params.id;
    if (this.city_id) {
      this.http.get<any>(environment.apiBaseURL + '/city/' + this.city_id, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          data = data.data

          this.addCityForm = new FormGroup({
            city: new FormControl(data.city || "", [Validators.required]),
            code: new FormControl(data.code || ""),
            State: new FormControl(Number(data.state_id) || null, [Validators.required]),
            taluka: new FormControl(data.taluka || ""),
            is_active: new FormControl(data.is_active),
            city_hi: new FormControl(data.city_hi || ""),
            city_gu: new FormControl(data.city_gu || "")
          });

        },
        error: error => {
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }

    this.http.get<any>(environment.apiBaseURL + '/geocoding/state/41', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        response = response.data
        this.States = response;
      },
      error: error => {
        this.States = [];
      }
    })
  }

  get cityFormControl() {
    return this.addCityForm.controls;
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.addCityForm.valid) {
      // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.addCityForm.value);

      let data = this.addCityForm.value;

      let dataToSend = {
        "city": data.city || "",
        "code": data.code || "",
        "taluka": data.taluka || "",
        "state_id": data.State || null,
        "is_active": data.is_active || false,
        "city_hi": data.city_hi || "",
        "city_gu": data.city_gu || "",
      }
      console.log(dataToSend);

      if (this.city_id) {
        this.http.put(environment.apiBaseURL + '/city/' + this.city_id, dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.router.navigate(['city']);
            this.notifications.create("Success",
              "City Updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else {
        this.http.post(environment.apiBaseURL + '/city', dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            this.router.navigate(['city']);
            this.notifications.create("Success",
              "City Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }
  onCancel() {
    this.router.navigate(['city']);
  }

}
