<mat-card>
    <mat-card-title>Variant
    </mat-card-title>

    <mat-card-content>
        <form [formGroup]="varientForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">

            <mat-card-content style="margin-top: 2em;border: 1px solid black;padding: 2em; border-radius: 1em;">

                <!-- <button type="button" mat-raised-button *ngIf="i!==0"
            style="position: absolute;left: 3em;background-color: red;" color="primary"
            (click)="removeVarient(i)">
            <mat-icon>close</mat-icon>
          </button> -->
                <button type="button" mat-raised-button style="width: fit-content;height: fit-content;position: absolute;right: 3em;" color="primary" (click)="addAttributes()">
            Add Varient Attribute
          </button>
                <form [formGroup]="varientForm" class="example-form" fxLayoutAlign="stretch" fxLayout="column" style=" margin-top: 3em;">

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Name</mat-label>
                        <input matInput placeholder="Varient Name" required ngModel name="varientName" formControlName="varientName" />
                        <mat-error *ngIf="(varientFormControl.varientName.touched || varientFormControl.varientName.dirty) && varientFormControl.varientName.errors?.required">
                            varient name is required</mat-error>
                    </mat-form-field>

                    <mat-grid-list cols="2" rowHeight="5em">
                        <mat-grid-tile>
                            <mat-form-field class="example-full-width" style="width: 100%;margin-right: 1em;">
                                <mat-label>varient thumb</mat-label>
                                <input matInput placeholder="Product Thumbnail" required ngModel name="varientThumb" formControlName="varientThumb" />
                                <mat-error *ngIf="(varientFormControl.varientThumb.touched || varientFormControl.varientThumb.dirty ) && varientFormControl.varientThumb.errors?.required">
                                    Varient thumbnail is required</mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <label>OR</label>
                            <!-- <mat-file-upload style="width: 100%;" [labelText]="''" [selectButtonText]="'Choose File'"
                  [acceptedTypes]="'.png, .jpg, .jpeg'" [uploadButtonText]="'Upload File'" [allowMultipleFiles]="false"
                  [showUploadButton]="true" [customSvgIcon]="''" [acceptedTypes]="'.png, .jpg, .jpeg'"
                  (uploadClicked)="onUploadClicked()" (selectedFilesChanged)="onSelectedFilesChanged($event)">
                </mat-file-upload> -->
                        </mat-grid-tile>
                    </mat-grid-list>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Slug</mat-label>
                        <input matInput placeholder="Product Slug" required ngModel name="varientSlug" formControlName="varientSlug" />
                        <mat-error *ngIf="(varientFormControl.varientSlug.touched || varientFormControl.varientSlug.dirty) && varientFormControl.varientSlug.errors?.required">
                            Varient slug is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient MRP</mat-label>
                        <input matInput placeholder="Product MRP" required ngModel name="varientMRP" formControlName="varientMRP" />
                        <mat-error *ngIf="(varientFormControl.varientMRP.touched || varientFormControl.varientMRP.dirty) && varientFormControl.varientMRP.errors?.required">
                            Varient MRP is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Price</mat-label>
                        <input matInput placeholder="Product Price" required ngModel name="varientPrice" formControlName="varientPrice" />
                        <mat-error *ngIf="(varientFormControl.varientPrice.touched || varientFormControl.varientPrice.dirty) && varientFormControl.varientPrice.errors?.required">
                            Varient price is required</mat-error>
                    </mat-form-field>

                    <!-- <mat-form-field class="example-full-width">
              <mat-label>varient SKU</mat-label>
              <input matInput placeholder="Product SKU" required ngModel name="varientSKU" formControlName="varientSKU" />
              <mat-error
                *ngIf="(varientFormControl.varientSKU.touched || varientFormControl.varientSKU.dirty) && varientFormControl.varientSKU.errors?.required">
                varient SKU is required</mat-error>
            </mat-form-field> -->

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Discount</mat-label>
                        <input matInput placeholder="Product Discount" readonly required [ngModel]="((100 * (varientForm.value.varientMRP - varientForm.value.varientPrice) / varientForm.value.varientMRP)) || 0" name="varientDiscount" formControlName="varientDiscount" />
                        <mat-error *ngIf="(varientFormControl.varientDiscount.touched || varientFormControl.varientDiscount.dirty) && varientFormControl.varientDiscount.errors?.required">
                            Varient Discount is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Weight</mat-label>
                        <input matInput placeholder="varient Weight" required ngModel name="varientWeight" formControlName="varientWeight" />
                        <mat-error *ngIf="(varientFormControl.varientWeight.touched || varientFormControl.varientWeight.dirty) && varientFormControl.varientWeight.errors?.required">
                            varient weight is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-label>varient Qtys</mat-label>
                        <input matInput placeholder="varient Qtys" required ngModel name="varientQtys" formControlName="varientQtys" />
                        <mat-error *ngIf="(varientFormControl.varientQtys.touched || varientFormControl.varientQtys.dirty) && varientFormControl.varientQtys.errors?.required">
                            varient qtys is required</mat-error>
                    </mat-form-field>

                    <mat-grid-list cols="2" rowHeight="5em">
                        <mat-grid-tile>
                            <section class="example-section" style="width: 100%;">
                                <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="stockStatus" formControlName="stockStatus">Out ff Stock</mat-checkbox>
                                <!-- <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="isFeatured" formControlName="isFeatured">Is Featured</mat-checkbox> -->
                            </section>
                        </mat-grid-tile>
                    </mat-grid-list>

                </form>

                <mat-card-content *ngFor="let varientAttribute of varientAttributesForms; let j = index;" style="margin-top: 2em;border: 1px solid black;padding: 0 2em 0 2em; border-radius: 1em;">

                    <mat-icon style="color: red;right: 2.5em;position: absolute;margin-top: 0.5em;" (click)="removeAttributes(j)">close</mat-icon>
                    <form [formGroup]="varientAttribute" class="example-form" fxLayoutAlign="stretch" fxLayout="column" style=" margin-top: 4em;">

                        <mat-grid-list cols="2" rowHeight="5em">
                            <mat-grid-tile>
                                <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
                                    <mat-label>AttributeGroup</mat-label>
                                    <mat-select required [ngModel] name="attributeGroup" formControlName="attributeGroup" (selectionChange)="getAttributes($event, j)">
                                        <mat-option *ngFor="let attributeGroup of AttributeGroups" [value]="attributeGroup.id">{{ attributeGroup.attributeGroupName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="(varientAttribute.controls.attributeGroup.touched || varientAttribute.controls.attributeGroup.dirty ) && varientAttribute.controls.attributeGroup.errors?.required">
                                        AttributeGroup is required</mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile>
                                <mat-form-field appearance="fill" style="width: 100%;">
                                    <mat-label>Attribute</mat-label>
                                    <mat-select required [ngModel] name="attribute" formControlName="attribute">
                                        <mat-option *ngFor="let attribute of varientAttributes[j]" [value]="attribute.id">{{ attribute.attributeName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="(varientAttribute.controls.attribute.touched || varientAttribute.controls.attribute.dirty ) && varientAttribute.controls.attribute.errors?.required">
                                        Attribute is required</mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </form>
                </mat-card-content>
            </mat-card-content>

            <mat-grid-list cols="2" rowHeight="5em">
                <button mat-raised-button color="primary" class="buttom-button"> Save </button>
                <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
            </mat-grid-list>
        </form>

    </mat-card-content>



    <div id="loader" *ngIf="flag">
        <mat-progress-spinner id="loader-com" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</mat-card>
<simple-notifications></simple-notifications>