<mat-card>
    <mat-card-title>Attribute Group</mat-card-title>
    <mat-card-content>
      <form [formGroup]="addAttributeGroupNameForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">
  
        <mat-form-field class="example-full-width">
          <mat-label>Attribute Group Name</mat-label>
          <input matInput placeholder="AttributeGroupName" required ngModel name="attributeGroupName" formControlName="attributeGroupName" />
          <mat-error *ngIf="(attributeGroupFormControl.attributeGroupName.touched || attributeGroupFormControl.attributeGroupName.dirty) && attributeGroupFormControl.attributeGroupName.errors?.required">Attribute group name is required</mat-error>
        </mat-form-field>
  

        <button mat-raised-button color="primary" class="buttom-button">
          Save
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <simple-notifications></simple-notifications>
  