<mat-card>
    <mat-card-title>Product Edit
        <!-- <button mat-raised-button style="width: 80px;height: 40px;padding: unset;position: absolute;right: 1em;"
        color="primary" (click)="AddVarientClick()">
        Add Varient
      </button> -->
    </mat-card-title>

    <mat-card-content>
        <form [formGroup]="addProductForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">

            <mat-form-field class="example-full-width">
                <mat-label>Product Name</mat-label>
                <input matInput placeholder="Product Name" required ngModel name="productName" formControlName="productName" />
                <mat-error *ngIf="(newsFormControl.productName.touched || newsFormControl.productName.dirty) && newsFormControl.productName.errors?.required">
                    Product name is required</mat-error>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width">
          <mat-label>Product SKU</mat-label>
          <input matInput placeholder="Product SKU" required ngModel name="productSKU" formControlName="productSKU" />
          <mat-error
            *ngIf="(newsFormControl.productSKU.touched || newsFormControl.productSKU.dirty) && newsFormControl.productSKU.errors?.required">
            Product SKU is required</mat-error>
        </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width">
          <mat-label>Product MRP</mat-label>
          <input matInput placeholder="Product MRP" required ngModel name="productMRP" formControlName="productMRP" />
          <mat-error
            *ngIf="(newsFormControl.productMRP.touched || newsFormControl.productMRP.dirty) && newsFormControl.productMRP.errors?.required">
            Product MRP is required</mat-error>
        </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width">
          <mat-label>Product Price</mat-label>
          <input matInput placeholder="Product Price" required ngModel name="productPrice"
            formControlName="productPrice" />
          <mat-error
            *ngIf="(newsFormControl.productPrice.touched || newsFormControl.productPrice.dirty) && newsFormControl.productPrice.errors?.required">
            Product price is required</mat-error>
        </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width">
          <mat-label>Product Discount</mat-label>
          <input matInput placeholder="Product Discount" readonly required [ngModel]="((100*(addProductForm.value.productMRP - addProductForm.value.productPrice))/addProductForm.value.productMRP) || 0" name="productDiscount"
            formControlName="productDiscount" />
          <mat-error
            *ngIf="(newsFormControl.productDiscount.touched || newsFormControl.productDiscount.dirty) && newsFormControl.productDiscount.errors?.required">
            Product Discount is required</mat-error>
        </mat-form-field> -->

            <mat-form-field class="example-full-width">
                <mat-label>Product Short Description</mat-label>
                <input matInput placeholder="Product Short Description" required ngModel name="productShortDescription" formControlName="productShortDescription" />
                <mat-error *ngIf="(newsFormControl.productShortDescription.touched || newsFormControl.productShortDescription.dirty) && newsFormControl.productShortDescription.errors?.required">
                    Prouct Short Description is required</mat-error>
            </mat-form-field>

            <mat-label *ngIf="addProductForm.value.newsType != 'Other News'">Product Description</mat-label>
            <ckeditor required ngModel name="productDescription" formControlName="productDescription"></ckeditor>
            <!-- <quill-editor [styles]="{ height: '235px' }" required ngModel name="productDescription"
          formControlName="productDescription"></quill-editor> -->
            <mat-error *ngIf="(newsFormControl.productDescription.touched || newsFormControl.productDescription.dirty ) && newsFormControl.productDescription.errors?.required">
                Product description is required</mat-error>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <mat-form-field class="example-full-width" style="width: 100%;margin-right: 1em;">
                        <mat-label>Product thumb</mat-label>
                        <input matInput placeholder="Product Thumbnail" required ngModel name="productThumb" formControlName="productThumb" />
                        <mat-error *ngIf="(newsFormControl.productThumb.touched || newsFormControl.productThumb.dirty ) && newsFormControl.productThumb.errors?.required">
                            Product thumbnail is required</mat-error>
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <label>OR</label>
                    <!-- <mat-file-upload style="width: 100%;" [labelText]="''" [selectButtonText]="'Choose File'"
              [acceptedTypes]="'.png, .jpg, .jpeg'" [uploadButtonText]="'Upload File'" [allowMultipleFiles]="false"
              [showUploadButton]="true" [customSvgIcon]="''" [acceptedTypes]="'.png, .jpg, .jpeg'"
              (uploadClicked)="onUploadClicked()" (selectedFilesChanged)="onSelectedFilesChanged($event)">
            </mat-file-upload> -->
                </mat-grid-tile>
            </mat-grid-list>


            <mat-form-field class="example-full-width">
                <mat-label>Product Slug</mat-label>
                <input matInput placeholder="Product Slug" required ngModel name="productSlug" formControlName="productSlug" />
                <mat-error *ngIf="(newsFormControl.productSlug.touched || newsFormControl.productSlug.dirty) && newsFormControl.productSlug.errors?.required">
                    Prouct slug is required</mat-error>
            </mat-form-field>

            <mat-grid-list cols="2" rowHeight="5em">
                <mat-grid-tile>
                    <section class="example-section" style="width: 100%;">
                        <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="stockStatus" formControlName="stockStatus">Out of Stock</mat-checkbox>
                        <!-- <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="isFeatured" formControlName="isFeatured">Is Featured</mat-checkbox> -->
                    </section>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
              <mat-form-field style="width: 100%;">
                <mat-label>Category</mat-label>
                <mat-select required ngModel name="category" formControlName="category">
                  <mat-option *ngFor="let category of Categories" [value]="category.id">{{ category.categoryName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
              <mat-form-field style="width: 100%;">
                <mat-label>Tax Slab</mat-label>
                <mat-select required ngModel name="taxSlab" formControlName="taxSlab">
                  <mat-option *ngFor="let tax_slab of TaxSlab" [value]="tax_slab.id">{{ tax_slab.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="5em">
          <button mat-raised-button color="primary" class="buttom-button"> Save </button>
          <button mat-raised-button color="primary" class="buttom-button" (click) = "onCancel()"> Cancel </button>
        </mat-grid-list>
        </form>

    </mat-card-content>

</mat-card>
<simple-notifications></simple-notifications>