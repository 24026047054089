<mat-card>
  <mat-card-header>
    <mat-card-title> Order
      <mat-form-field style="margin-left: 10px;" class="example-form-field">
        <mat-label>Search</mat-label>
        <input matInput type="text" [(ngModel)]="searchTerm" (keyup)="searchItems($event)">
        <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchData()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-card-title>
    <div style="position: absolute;right: 150px;font-size: larger;padding-top: 10px;">Results : {{ total }} of {{ count
      }}</div>
    <div fxFlex>
    </div>
    <!-- <button mat-raised-button style="width: fit-content;height: 40px;padding: unset;" color="primary" (click)="AddNewsClick()">
        Add Category
      </button> -->
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>No.</mat-label>
        <mat-select [ngModel]="numberOfRecords" (selectionChange)="chnageRecords($event)">
          <mat-option [value]="5">5</mat-option>
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
        </mat-select>
      </mat-form-field>
      <button style="margin:10px;" mat-raised-button color="secondary" (click)="getNewRecords('prev')">
        Prev
      </button>
      <button style="margin:10px;" mat-raised-button color="secondary" (click)="getNewRecords('next')">
        Next
      </button>
    </div>

    <table style="width:100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Sr.No</th>
        <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="orderDate">
        <th mat-header-cell *matHeaderCellDef>Order Date</th>
        <td mat-cell *matCellDef="let element">{{ !element.orderDate ? '-' : element.orderDate | date: 'short' }}</td>
        <!-- <td mat-cell *ngIf="!element.orderDate" *matCellDef="let element">-</td> -->
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Full Name</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>
      <ng-container matColumnDef="paymentStatus">
        <th mat-header-cell *matHeaderCellDef>Payment Status</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentStatus }}</td>
      </ng-container>
      <ng-container matColumnDef="paymentMode">
        <th mat-header-cell *matHeaderCellDef>Payment Mode</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentMode }}</td>
      </ng-container>
      <ng-container matColumnDef="orderFinalAmount">
        <th mat-header-cell *matHeaderCellDef>Order FinalAmount</th>
        <td mat-cell *matCellDef="let element">{{ element.orderFinalAmount|| 0 }}</td>
      </ng-container>
      <ng-container matColumnDef="orderStatus">
        <th mat-header-cell *matHeaderCellDef>Order Status</th>
        <td mat-cell *matCellDef="let element">{{ element.orderStatus }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Timestamp</th>
        <td mat-cell *matCellDef="let element">{{ element.createdDate | date:'short' }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" style="display: flex;">
          <a style="margin:10px;" mat-raised-button [routerLink]="['..','order-edit',element.id]"
            color="primary">Edit</a>

          <!-- <button
            style="margin:10px;"
              mat-raised-button
              color="secondary"
              (click)="removeClick(element.id)"
            >
              Delete
            </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

  </mat-card-content>


</mat-card>
<simple-notifications></simple-notifications>
<div *ngIf="showSpinner" class="spinner-wrapper">
  <mat-spinner></mat-spinner>
</div>