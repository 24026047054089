<mat-card>
    <mat-card-title>User Profile</mat-card-title>
    <mat-card-content>
      <form [formGroup]="userProfileForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column">
  
        <mat-form-field class="example-full-width">
          <mat-label>User Email</mat-label>
          <input matInput placeholder="User Email" required ngModel name="userEmail" formControlName="userEmail" />
          <mat-error *ngIf="(userFormControl.userEmail.touched || userFormControl.userEmail.dirty) && userFormControl.userEmail.errors?.required">userEmail is required</mat-error>
        </mat-form-field>
  
       <mat-form-field class="example-full-width">
          <mat-label>Full Name</mat-label>
          <input matInput placeholder="Full Name" required ngModel name="fullName" formControlName="fullName" />
          <mat-error *ngIf="(userFormControl.fullName.touched || userFormControl.fullName.dirty) && userFormControl.fullName.errors?.required">Full Name is required</mat-error>
        </mat-form-field>
  
        
  
        <mat-form-field class="example-full-width">
          <mat-label>Mobile</mat-label>
          <input matInput placeholder="Mobile Number" required ngModel name="mobile" formControlName="mobile"/>
          <mat-error *ngIf="(userFormControl.mobile.touched || userFormControl.mobile.dirty ) && userFormControl.mobile.errors?.required">Mobile Number is required</mat-error>
        </mat-form-field>
  
        <button mat-raised-button color="primary" class="buttom-button">
          Save
        </button>
      </form>
    </mat-card-content>
  </mat-card>
  <simple-notifications></simple-notifications>
  