import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface News {
  id: number;
  categoryName: string;
  createdDate: string;
}

const ELEMENT_DATA: News[] = [];
const countManage = {
  limit: 10,
  offsef: 0,
};

@Component({
  selector: 'app-news-category',
  templateUrl: './news-category.component.html',
  styleUrls: ['./news-category.component.css']
})
export class NewsCategoryComponent implements OnInit {
  total = 0;
  count = 0;
  searchTerm = '';
  numberOfRecords = 10;
  searchSub: Subscription;
  showSpinner = false;
  displayedColumns: string[] = [
    'id',
    'categoryName',
    'createdDate',
    'delete',
  ];
  dataSource = ELEMENT_DATA;
  access_token = localStorage.getItem('access_token')

  constructor(private router: Router, private http: HttpClient, private notifications: NotificationsService, private translate: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getItems();
  }

  AddNewsClick() {
    this.router.navigate(['/news-category-add']);
  }

  clearSearchData() {
    this.searchTerm = '';
    this.getItems();

  }
  searchItems(event) {
    countManage.offsef = 0;
    this.getItems();
  }
  getItems() {
    this.showSpinner = true;
    const url = `${environment.apiBaseURL}/news-category?limit=${countManage.limit}&offset=${countManage.offsef}&searchTerm=${this.searchTerm}`;
    this.http.get<any>(url, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        this.count = data.total;
        if (data.data) {
          this.showSpinner = false;
          this.dataSource = data.data;
          this.total = data.data.length;
        } else {
          this.total = data.total || 0;
        }
      },
      error: error => {
        this.showSpinner = false;
        this.dataSource = [];
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    });
  }

  chnageRecords(event) {
    this.numberOfRecords = parseInt(event.value);
    countManage.limit = parseInt(event.value);
    this.getItems();
  }

  getNewRecords(event) {
    if (event == 'prev') {
      if ((this.total >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)) {
        countManage.offsef = countManage.offsef - countManage.limit;
        this.getItems();
      }
    }
    else {
      if (this.total >= countManage.offsef + countManage.limit) {
        countManage.offsef = countManage.offsef + countManage.limit;
        this.getItems();
      }
    }

  }
  removeClick(id) {
    let finalData = { message: `Are you sure you want to delete?`, value: '' }
    const dialogData = new ConfirmDialogModel("Confirm Action", finalData);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.showSpinner = true;
        this.http.delete(environment.apiBaseURL + '/news-category/' + id, {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.showSpinner = false;
            this.notifications.create(' Success ',
              'Successfully removed',
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            this.getItems();
          },
          error: error => {
            this.showSpinner = false;
            this.notifications.create('Error',
              'Something goes wrong!. Please try again later',
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        });
      }
    });
  }
}
