import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
// import CircularProgress from '@material-ui/core/CircularProgress';

interface NewsType {
  value: string;
  viewValue: string;
}

const sortDescriptionLength = 100;

@Component({
  selector: 'app-varient-edit',
  templateUrl: './varient-edit.component.html',
  styleUrls: ['./varient-edit.component.css']
})
export class VarientEditComponent implements OnInit {
  selectedType = "Other News"
  public varientForm: FormGroup;
  public varientAttributesForms: FormGroup[] = [];
  public varientAttributes = [];
  access_token = localStorage.getItem('access_token')
  product_id: string = null;
  variant_id: string = null;
  flag = false;
  file = null;
  varientBox = null;

  // @ViewChild('varientBox') varientBox: ElementRef;

  newsType: NewsType[] = [
    { value: 'Khakiwala Original', viewValue: 'Khakiwala Original' },
    { value: 'Other News', viewValue: 'Other News' },
  ];


  AttributeGroups: string[] = [];
  Attributes: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private elRef: ElementRef) {
    this.varientForm = new FormGroup({
      varientName: new FormControl('', [Validators.required]),
      varientThumb: new FormControl('', [Validators.required]),
      varientSlug: new FormControl('', [Validators.required]),
      varientMRP: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      varientPrice: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      varientDiscount: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]), //[Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]
      // varientSKU: new FormControl('', [Validators.required]),
      varientWeight: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
      varientQtys: new FormControl('', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
      stockStatus: new FormControl(false),
    });

    this.varientAttributesForms[this.varientAttributesForms.length] = new FormGroup({
      attributeGroup: new FormControl('', [Validators.required]),
      attribute: new FormControl('', [Validators.required]),
    })


    this.product_id = this.actRoute.snapshot.params.pid;
    this.variant_id = this.actRoute.snapshot.params.vid;

    if (this.product_id && this.variant_id) {
      this.http.get<any>(environment.apiBaseURL + '/product/variant/' + this.product_id + "/" + this.variant_id, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          this.varientForm = new FormGroup({
            varientName: new FormControl(data.variantName, [Validators.required]),
            varientThumb: new FormControl(data.variantThumb || null, [Validators.required]),
            varientSlug: new FormControl(data.variantSlug || null, [Validators.required]),
            varientMRP: new FormControl(data.variantMRP || null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            varientPrice: new FormControl(data.variantPrice || null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            varientDiscount: new FormControl(data.variantDiscount || 0, [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]), //[Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]
            // varientSKU: new FormControl('', [Validators.required]),
            varientWeight: new FormControl(data.variantWeight || null, [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
            varientQtys: new FormControl(data.variantQtys || null, [Validators.required, Validators.pattern(/^(0|[1-9]\d*)?$/)]),
            stockStatus: new FormControl(data.stockStatus || false),
          })

          data.variantAttribute.forEach((item, index) => {
            this.varientAttributesForms[index] = new FormGroup({
              attributeGroup: new FormControl(item.attributeGroup.id, [Validators.required]),
              attribute: new FormControl(item.attribute.id, [Validators.required]),
            })
          });

          data.variantAttribute.forEach((item, index) => {
            this.http.get<any>(environment.apiBaseURL + '/product/attribute-group/attributes/' + item.attributeGroup.id, {
              headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.access_token
              })
            }).subscribe({
              next: (response) => {
                this.varientAttributes[index] = response;
                console.log("index: ", this.varientAttributes);
                this.flag = false;
              },
              error: err => {
                this.flag = false;
              }
            })
          });


        },
        error: error => {
          this.notifications.create("Error",
            "Something goes wrong!. Please try again later",
            NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }

    let attrGrp = this.http.get<any>(environment.apiBaseURL + '/product/attribute-group', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    });


    forkJoin([attrGrp]).subscribe({
      next: response => {
        this.AttributeGroups = response[0];
      },
      error: err => {
        this.AttributeGroups = [];
      }
    })
  }

  // changeNewsType(data) {
  //   if (data.value == "Khakiwala Original") {
  //     this.addProductForm.controls.description.setValidators([Validators.required]);
  //     this.addProductForm.controls.source.setValidators([]);
  //     this.addProductForm.controls.sourceLink.setValidators([]);
  //   }
  //   else {
  //     this.addProductForm.controls.description.setValidators([]);
  //     this.addProductForm.controls.source.setValidators([Validators.required]);
  //     this.addProductForm.controls.sourceLink.setValidators([Validators.required]);
  //   }
  //   this.addProductForm.controls.description.updateValueAndValidity();
  // }

  onUploadClicked() {
    let formData = new FormData();
    formData.append('file', this.file);
    this.http.post(environment.imageBaseURL, formData, {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + environment.imageToken
      })
    }).subscribe({
      next: (response) => {
        if (response['statusCode'] && response['statusCode'] == 200) {
          this.varientForm.patchValue({ 'varientThumb': 'http://api.khakiwala.com:8080/' + response['data'] })
        }
      },
      error: (error) => {
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  onSelectedFilesChanged(data) {
    if (data && data[0]) {
      this.file = data[0];
    }
  }

  getAttributes(event, parent) {
    console.log('parent : ', parent)
    this.flag = true;
    this.http.get<any>(environment.apiBaseURL + '/product/attribute-group/attributes/' + event.value, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        this.varientAttributes[parent] = response;
        this.Attributes = response;
        this.flag = false;
      },
      error: err => {
        this.Attributes = [];
        this.flag = false;
      }
    })
  }

  get varientFormControl() {
    return this.varientForm.controls;
  }

  ngOnInit(): void { }

  onSubmit() {
    let varientAttributesValidation = this.varientAttributesForms.map(singleForm => { if (!singleForm.valid) return true; }).filter(item => item);
    console.log("000")
    if (this.varientForm.valid && varientAttributesValidation.length == 0) {
      console.log("111")
      //   // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      //   console.table(this.addProductForm.value);
      let data = this.varientForm.value;
      let varientAttributesData = [];

      varientAttributesData = this.varientAttributesForms.map((itemChild, index) => {
        let singleFormData = itemChild.value;
        return {
          "attributeGroup": singleFormData.attributeGroup || 0,
          "attribute": singleFormData.attribute || 0
        }
      })

      let dataToSend = {
        "variantName": data.varientName || "",
        "variantThumb": data.varientThumb || "",
        "variantSlug": data.varientSlug || "",
        "variantMRP": Number(data.varientMRP) || 0,
        "variantPrice": Number(data.varientPrice) || 0,
        "variantDiscount": Number(data.varientDiscount) || 0,
        "variantSKU": data.varientSKU || "",
        "variantWeight": Number(data.varientWeight) || 0,
        "variantQtys": Number(data.varientQtys) || 1,
        "variantCountryOfOrigin": data.varientCountryOfOrigin || "",
        "stockStatus": data.stockStatus || false,
        "variantAttribute": varientAttributesData || []
      }

      console.log("data : ", dataToSend);

      if (this.product_id && this.variant_id) {
        this.http.put(environment.apiBaseURL + '/product/variant/' + this.product_id + "/" + this.variant_id, dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: async (data) => {

            this.notifications.create("Success",
              "Variant successfully updated",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            // this.getItems();
            this.router.navigate(['product', this.product_id, 'variants']);
          },
          error: error => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else {
        this.http.post(environment.apiBaseURL + '/product/variant/' + this.product_id, dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: async (response) => {
            await this.notifications.create("Success",
              "Varian successfully added",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
            this.router.navigate(['product', this.product_id, 'variants']);
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }

  addAttributes() {
    if (!this.varientAttributesForms) {
      this.varientAttributesForms = [];
    }
    this.varientAttributesForms[this.varientAttributesForms.length] = new FormGroup({
      attributeGroup: new FormControl('', [Validators.required]),
      attribute: new FormControl('', [Validators.required]),
    })

  }

  removeAttributes(childIndex) {
    this.varientAttributesForms.splice(childIndex, 1);
  }

  onCancel() {
    this.router.navigate(['product', this.product_id, 'variants']);
  }

}



