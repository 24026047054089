import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

const ELEMENT_DATA = [];
const countManage = {
  limit: 10,
  offsef: 0,
}

@Component({
  selector: 'app-attribute-group',
  templateUrl: './attribute-group.component.html',
  styleUrls: ['./attribute-group.component.css']
})
export class AttributeGroupComponent implements OnInit {
  attributeGroupName = '';
  displayedColumns: string[] = [
    'id',
    'attributeGroupName',
    'action',
  ];
  dataSource = ELEMENT_DATA;
  attr_group_id = null;
  showSpinner = false;
  access_token = localStorage.getItem('access_token')

  constructor(private router: Router, private http: HttpClient, public dialog: MatDialog, private notifications: NotificationsService) { }

  ngOnInit(): void {
    this.getItems();
  }

  addAttributeGroup() {

    if (this.attributeGroupName && this.attributeGroupName != "") {
      let dataToSend = {
        "attributeGroupName": this.attributeGroupName || "",
      }


      if (this.attr_group_id) {
        this.http.put(environment.apiBaseURL + '/product/attribute-group/' + this.attr_group_id, dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            // this.router.navigate(['product', 'attribute-group']);
            this.getItems();
            this.notifications.create("Success",
              "Attribute Group updated Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: error => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else {
        this.http.post(environment.apiBaseURL + '/product/attribute-group', dataToSend, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            // this.router.navigate(['product', 'attribute-group']);
            this.getItems();
            this.notifications.create("Success",
              "Attribute Group Added Successfully",
              NotificationType.Success, { timeOut: 3000, showProgressBar: true });
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }

  }

  clearData() {
    this.attributeGroupName = '';
    this.getItems();
  }

  getItems() {
    const url = `${environment.apiBaseURL}/product/attribute-group`;
    this.showSpinner = true;
    this.http.get<any>(url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        if (data) {
          this.showSpinner = false;
          this.dataSource = data;
        }
        //this.total = data.total || 0;
      },
      error: error => {
        this.showSpinner = false;
        this.dataSource = [];
        this.notifications.create("Error",
          "Something goes wrong!. Please try again later",
          NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  // chnageRecords(event){
  //   this.numberOfRecords = parseInt(event.value);
  //   countManage.limit = parseInt(event.value);
  //   this.getItems();
  // }

  // getNewRecords(event){
  //   if(event == 'prev'){
  //     if((this.total >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)){
  //       countManage.offsef = countManage.offsef - countManage.limit;
  //       this.getItems();
  //     }
  //   }
  //   else{
  //     if(this.total >= countManage.offsef + countManage.limit){
  //       countManage.offsef = countManage.offsef + countManage.limit;
  //       this.getItems();
  //     }
  //   }

  // }


  manageChild(id, attributeGroupName ) {
    this.router.navigate(['product', 'attribute-group', 'attributes', id,{attribute: attributeGroupName}]);
  }
}


