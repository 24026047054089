<mat-card>
  <mat-card-header style="margin-bottom: 2em;">
    <mat-card-title> Attribute Group
    </mat-card-title>
    <div style="position: absolute;right: 3em;">
      <mat-form-field style="margin-left: 10px;" class="example-form-field">
        <mat-label>Attribute Group Name</mat-label>
        <input matInput type="text" [(ngModel)]="attributeGroupName" (keyup)="getItems()">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="attributeGroupName"
          (click)="clearData()">
          <mat-icon>close</mat-icon>
        </button>

      </mat-form-field>
      <button mat-raised-button color="secondary" (click)="addAttributeGroup()"
        style="margin-top: 0.7em; margin-left: 2em; height: fit-content;">
        Save
      </button>
    </div>
  </mat-card-header>

  <mat-card-content>

    <table style="width:100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Atr.Id</th>
        <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="attributeGroupName">
        <th mat-header-cell *matHeaderCellDef>Attribute GroupName</th>
        <td mat-cell *matCellDef="let element">{{ element.attributeGroupName }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" style="display: flex;">
          <button style="margin:10px;" mat-raised-button color="secondary"
            (click)="manageChild(element.id, element.attributeGroupName )">
            Attributes
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

  </mat-card-content>


</mat-card>
<div *ngIf="showSpinner" class="spinner-wrapper">
  <mat-spinner></mat-spinner>
</div>