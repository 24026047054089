<mat-card>
  <mat-card-title>News Category</mat-card-title>
  <mat-card-content>
    <form [formGroup]="addNewsForm" class="example-form" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch"
      fxLayout="column">

      <mat-form-field class="example-full-width">
        <mat-label>Category Name</mat-label>
        <input matInput placeholder="Category Name" required ngModel name="categoryName"
          formControlName="categoryName" />
        <mat-error
          *ngIf="(newsFormControl.categoryName.touched || newsFormControl.categoryName.dirty) && newsFormControl.categoryName.errors?.required">
          Category Name is required</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Category Short Description</mat-label>
        <input matInput placeholder="Short Description" required ngModel name="categoryShortDescription"
          formControlName="categoryShortDescription" />
        <mat-error
          *ngIf="(newsFormControl.categoryShortDescription.touched || newsFormControl.categoryShortDescription.dirty) && newsFormControl.categoryShortDescription.errors?.required">
          Category Short Description is required</mat-error>
      </mat-form-field>

      <mat-label style="font-weight: 400;">Category Description</mat-label>
      <ckeditor required ngModel name="categoryDescription" formControlName="categoryDescription"></ckeditor>
      <!-- <quill-editor required [styles]="{ height: '235px' }" ngModel name="categoryDescription" formControlName="categoryDescription"></quill-editor> -->
      <mat-error
        *ngIf="(newsFormControl.categoryDescription.touched || newsFormControl.categoryDescription.dirty ) && newsFormControl.categoryDescription.errors?.required">
        Category Description is required</mat-error>

      <mat-form-field class="example-full-width">
        <mat-label>Category Image</mat-label>
        <input matInput placeholder="Category image" required ngModel name="categoryImage"
          formControlName="categoryImage" />
        <mat-error
          *ngIf="(newsFormControl.categoryImage.touched || newsFormControl.categoryImage.dirty ) && newsFormControl.categoryImage.errors?.required">
          Category Image is required</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Category Slug</mat-label>
        <input matInput placeholder="Category Slug" required ngModel name="categorySlug"
          formControlName="categorySlug" />
        <mat-error
          *ngIf="(newsFormControl.categorySlug.touched || newsFormControl.categorySlug.dirty ) && newsFormControl.categorySlug.errors?.required">
          Category Slug is required</mat-error>
      </mat-form-field>

      <mat-grid-list cols="2" rowHeight="5em">
        <mat-grid-tile>
          <mat-form-field appearance="fill" style="width: 100%; margin-right: 1em;">
            <mat-label>Parent Category</mat-label>
            <mat-select ngModel name="parentCategory" formControlName="parentCategory">
              <mat-option *ngFor="let category of Categories" [value]="category.id">{{
                category.categoryName
                }}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="(newsFormControl.category.touched || newsFormControl.category.dirty ) && newsFormControl.category.errors?.required">Category is required</mat-error> -->
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <section class="example-section" style="width: 100%;">
            <mat-checkbox style="margin: 10px;" color="primary" class="example-margin" ngModel name="isVisibleInMobile"
              formControlName="isVisibleInMobile">IsVisibleInMobile</mat-checkbox>
          </section>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-form-field class="example-full-width">
        <mat-label>Order</mat-label>
        <mat-select ngModel name="order" formControlName="order">
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="4">4</mat-option>
          <mat-option [value]="5">5</mat-option>
          <mat-option [value]="6">6</mat-option>
          <mat-option [value]="7">7</mat-option>
          <mat-option [value]="8">8</mat-option>
          <mat-option [value]="9">9</mat-option>
          <mat-option [value]="10">10</mat-option>

        </mat-select>
        <!-- <input matInput placeholder="Category Slug" required ngModel name="order" formControlName="order"/> -->
        <mat-error
          *ngIf="(newsFormControl.order.touched || newsFormControl.order.dirty ) && newsFormControl.order.errors?.required">
          Order is required</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width" *ngFor="let item of languageFields | keyvalue">
        <mat-label>{{ item.value | titlecase}}</mat-label>
        <input matInput placeholder="{{item.value | titlecase}}" required ngModel name="{{item.key}}"
          formControlName="{{item.key}}" />
      </mat-form-field>

      <mat-grid-list cols="2" rowHeight="5em">
        <button mat-raised-button color="primary" class="buttom-button"> Save </button>
        <button mat-raised-button color="primary" class="buttom-button" (click)="onCancel()"> Cancel </button>
      </mat-grid-list>
    </form>
  </mat-card-content>
</mat-card>
<simple-notifications></simple-notifications>