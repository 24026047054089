import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface SignInCredentials {
  username: string;
  password: string;
}

export interface CreateCredentials {
  username: string;
  password: string;
  displayName: string;
}

export interface PasswordReset {
  code: string;
  newPassword: string;
}

export interface User{
  userData?: Object,
  access_token?: string
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user:User = {
    userData: '',
    access_token: ''
  }
  constructor(private http:HttpClient, private router: Router) { }

  signIn(credentials: SignInCredentials) {
    let url = environment.apiBaseURL + '/user/login';
    let dataToSend = {username:credentials.username.toString(),password:credentials.password}
    // this.user.username = credentials.username;
    return this.http.post<any>(url,dataToSend);
  }

  signOut = () => {
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  setUserData(Data: User){
    localStorage.setItem('user', JSON.stringify(Data.userData['user']));
    localStorage.setItem('access_token', Data.access_token)
  }
}
