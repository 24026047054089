import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ConfigService } from 'src/app/shared/config/config.service';
import { environment } from 'src/environments/environment';

interface NewsType {
  value: string;
  viewValue: string;
}

interface Language {
  value: string;
  viewValue: string;
}

interface Priority {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.css']
})
export class CategoryAddComponent implements OnInit {
  public addNewsForm: FormGroup;
  access_token = localStorage.getItem('access_token')
  news_id:string = null;
  languageFields = {};

  newsType: NewsType[] = [
    { value: 'Khakiwala Original', viewValue: 'Khakiwala Original' },
    { value: 'Other News', viewValue: 'Other News' },
  ];

  languageType: Language[] = [
    { value: 'en', viewValue: 'English'},
    { value: 'gu', viewValue: 'Gujarati'},
    { value: 'hi', viewValue: 'Hindi'},
  ]

  priorityType: Priority[] = [
    { value: 1, viewValue: 'High'},
    { value: 2, viewValue: 'Medium'},
    { value: 3, viewValue: 'Low'},
  ]

  Categories: string[] = [];

  constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private notifications: NotificationsService, private configService: ConfigService) {
    this.addNewsForm = new FormGroup({
      categoryName: new FormControl('', [Validators.required]),
      categoryShortDescription: new FormControl('', [Validators.required, Validators.maxLength(72)]),
      categoryDescription: new FormControl('', [Validators.required]),
      categoryImage: new FormControl('', [Validators.required]),
      categorySlug: new FormControl('', [Validators.required]),
      parentCategory: new FormControl(''),
      order: new FormControl('', [Validators.required,Validators.pattern("^[0-9]*$")]),
    });

    this.languageFields = configService.language ? configService.language : {};

    for (var key in this.languageFields) {
      if (this.languageFields.hasOwnProperty(key)) {
        this.addNewsForm.addControl(key,new FormControl('',[Validators.required]));
      }
    }

    this.news_id = this.actRoute.snapshot.params.id;
    if(this.news_id){
      this.http.get<any>(environment.apiBaseURL+'/category/'+this.news_id,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.access_token
        })
      }).subscribe({
        next: (data) => {
          this.addNewsForm = new FormGroup({
            categoryName: new FormControl(data.categoryName || "", [Validators.required]),
            categoryShortDescription: new FormControl(data.categoryShortDescription || "", [Validators.required, Validators.maxLength(72)]),
            categoryDescription: new FormControl(data.categoryDescription || null, [Validators.required]),
            categoryImage: new FormControl(data.categoryImage || "", [Validators.required]),
            categorySlug: new FormControl(data.categorySlug || "", [Validators.required]),
            parentCategory: new FormControl(data.parentId || ""),
            order: new FormControl(data.order || "", [Validators.required]),
          });
          let additionalCategoryName = JSON.parse(data.additionalCategoryName)
          if(additionalCategoryName){
            for (var key in additionalCategoryName) {
              if (this.languageFields.hasOwnProperty(key)) {
                this.addNewsForm.addControl(key,new FormControl(additionalCategoryName[key],[Validators.required]));
              }
            }
          }
        },
        error: error => {
          this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
        }
      })
    }

    this.http.get<any>(environment.apiBaseURL+'/category',{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (response) => {
        this.Categories = response.data;
      },
      error: error => {
        this.Categories = [];
      }
    })
  }

  get newsFormControl() {
    return this.addNewsForm.controls;
  }

  ngOnInit(): void {}

  onSubmit(){
    if (this.addNewsForm.valid) {
      // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.addNewsForm.value);

      let data = this.addNewsForm.value;
      let dataToSend = {
        "categoryName": data.categoryName || "",
        "categoryShortDescription": data.categoryShortDescription || "",
        "categoryDescription": data.categoryDescription || "",
        "categoryImage": data.categoryImage || "",
        "categorySlug": data.categorySlug || "",
        "parent": data.parentCategory || null,
        "order": data.order || null,
      }

      let additionalCategoryName = {}

      for (var key in this.languageFields) {
        if (this.languageFields.hasOwnProperty(key)) {
          additionalCategoryName[key] = data[key] || '';
        }
      }

      dataToSend['additionalCategoryName'] = additionalCategoryName;

      if(this.news_id){
        this.http.put(environment.apiBaseURL+'/category/'+this.news_id, dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (data) => {
            this.router.navigate(['category']);
          },
          error: error =>{
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
      else{
        this.http.post(environment.apiBaseURL+'/category',dataToSend,{
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.access_token
          })
        }).subscribe({
          next: (response) => {
            this.router.navigate(['category']);
          },
          error: (error) => {
            this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
          }
        })
      }
    }
  }
}
