<mat-card>
  <mat-card-header style="margin-bottom: 2em;">
    <mat-card-title> Attributes - {{atrribute_group_name}}
    </mat-card-title>
    <div style="position: absolute;right: 3em;">
      <mat-form-field style="margin-left: 10px;" class="example-form-field">
        <mat-label>Attribute</mat-label>
        <input matInput type="text" [(ngModel)]="attributeName">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="attributeName" (click)="clearData()">
          <mat-icon>close</mat-icon>
        </button>

      </mat-form-field>
      <button mat-raised-button color="secondary" (click)="addAttribute()"
        style="margin-top: 0.7em; margin-left: 2em; height: fit-content;">
        {{attribute_id ? 'Update' : 'Add'}}
      </button>
      <button *ngIf="attribute_id" mat-raised-button color="secondary" (click)="cancelManage()"
        style="margin-top: 0.7em; margin-left: 2em; height: fit-content;">
        Cancel
      </button>
    </div>

  </mat-card-header>

  <mat-card-content>
    <table style="width:100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8">


      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Atr.Id</th>
        <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="attributeName">
        <th mat-header-cell *matHeaderCellDef>Attribute</th>
        <td mat-cell *matCellDef="let element">{{ element.attributeName }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" style="display: flex;">
          <button style="margin:10px;" mat-raised-button color="secondary" (click)="manageAttribute(element.id)">
            Edit
          </button>
          <button style="margin:10px;" mat-raised-button color="danger" (click)="removeAttribute(element.id)">
            Delete
          </button>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

  </mat-card-content>


</mat-card>
<div *ngIf="showSpinner" class="spinner-wrapper">
  <mat-spinner></mat-spinner>
</div>