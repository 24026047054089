<div fxLayoutAlign="center center" fxFlexFill class="login-box">
  <mat-card fxFlex="25">
    <mat-toolbar color="primary">Login Page</mat-toolbar>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" fxLayoutAlign="stretch" fxLayout="column" class="login-form">
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" required ngModel name="username" formControlName="username"/>
        <mat-error *ngIf="(loginFormControl.username.touched || loginFormControl.username.dirty) && loginFormControl.username.errors?.required">UserName is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput type="password" placeholder="Password" required ngModel name="password" formControlName="password"/>
        <mat-error *ngIf="(loginFormControl.password.touched || loginFormControl.password.dirty) && loginFormControl.password.errors?.required">Password is required</mat-error>
      </mat-form-field>

      <button mat-raised-button type="submit" color="primary">
        Login
      </button>
    </form>
  </mat-card>
  <simple-notifications></simple-notifications>
</div>

