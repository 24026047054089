<mat-nav-list>
  <div class="profile-card">
    <div class="header">
      <h2>Khakiwala</h2>
    </div>
  
  </div>

  <mat-divider></mat-divider>

  <!-- <a mat-list-item routerLinkActive="list-item-active" routerLink="/">Dashboard</a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/category">Product Category</a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/news">News</a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/news-category">News Category</a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/job">Job</a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/product">Product</a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/product/attribute-group">Attribute Group</a>

  <a mat-list-item routerLinkActive="list-item-active" (click)="logout()">Logout</a> -->



  <!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <p (click)="navigate(node)">{{node.name}}</p>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button
              [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      {{node.name}}
    </mat-tree-node>
  </mat-tree> -->

  <app-menu-list-item *ngFor="let item of navItems" [item]="item">
  </app-menu-list-item>
  


</mat-nav-list>