import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  language = {
    'en': 'english',
    'hi': 'hindi',
    'gu': 'gujarati'
  };

  constructor() { }
}
