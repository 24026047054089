import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface News {
  id: number;
  categoryName: string;
  createdDate: string;
}

const ELEMENT_DATA: News[] = [];
const countManage = {
  limit: 10,
  offsef: 0,
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  total = 0;
  count = 0;
  searchTerm = '';
  numberOfRecords = 10;
  searchSub: Subscription;
  showSpinner = false;
  displayedColumns: string[] = [
    'id',
    'categoryName',
    'createdDate',
    'delete',
  ];
  dataSource = ELEMENT_DATA;
  access_token = localStorage.getItem('access_token')

  constructor(private router: Router, private http: HttpClient, private notifications: NotificationsService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.getItems();
  }

  AddNewsClick() {
    this.router.navigate(['/category-add']);
  }

  clearSearchData(){
    this.searchTerm = '';
    this.getItems();

  }
  searchItems(event){
    countManage.offsef = 0;
    this.getItems();
  }
  getItems(){
    this.showSpinner = true;
    const url = `${environment.apiBaseURL}/category?limit=${countManage.limit}&offset=${countManage.offsef}&searchTerm=${this.searchTerm}`;
    this.http.get<any>(url,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: data => {
        this.count = data.total;
        this.showSpinner = false;
        if (data.data) {
          this.dataSource = data.data;
        }
        this.total = data.data.length;
      },
      error: error => {
        this.dataSource = [];
        this.showSpinner = false;
        this.notifications.create("Error",
              "Something goes wrong!. Please try again later",
              NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    })
  }

  chnageRecords(event){
    this.numberOfRecords = parseInt(event.value);
    countManage.limit = parseInt(event.value);
    this.getItems();
  }

  getNewRecords(event){
    if(event == 'prev'){
      if((this.count >= countManage.offsef - countManage.limit) && (countManage.offsef - countManage.limit >= 0)){
        countManage.offsef = countManage.offsef - countManage.limit;
        this.getItems();
      }
    }
    else{
      if(this.count >= countManage.offsef + countManage.limit){
        countManage.offsef = countManage.offsef + countManage.limit;
        this.getItems();
      }
    }

  }
  removeClick(id) {
    this.http.delete(environment.apiBaseURL+'/category/'+id,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.access_token
      })
    }).subscribe({
      next: (data) => {
        this.notifications.create("Success",
          "Successfully removed",
          NotificationType.Success, { timeOut: 3000, showProgressBar: true });
        this.getItems();
      },
      error: error => {
        this.notifications.create("Error",
      "Something goes wrong!. Please try again later",
      NotificationType.Error, { timeOut: 3000, showProgressBar: true });
      }
    });
  }
}

